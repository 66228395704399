.story-main {
    width: 100vw;
    background-color: #000;
    background-image: url('../../../../../images/X.svg');
    background-repeat: space;
    background-size: 8%;
    display: flex;
    position: relative;
    flex-direction: column;
}
.story-main-content-mobile {
    display: none !important;
}
.story-main-content {
    color: #edf5fc;
    padding: 250px 0 0 240px;
    display: flex;
    flex-direction: column;
    max-width: 63% !important;
}
.story-main-content p:nth-child(1) {
    font-size: 50px;
    line-height: 108%;
    font-weight: 450;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    margin-bottom: 72px;
}
.story-main-content p:nth-child(2) {
    margin-bottom: 33px;
}
.story-main-content p:nth-child(2),
.story-main-content p:nth-child(3) {
    letter-spacing: -0.02em;
    font-size: 36px;
    line-height: 120%;
    color: rgba(218, 224, 242, 0.72);
    font-weight: lighter;
}
.story-ellipse {
    position: absolute;
    right: 140px;
    content: '';
    border-radius: 50%;
    width: 781px !important;
    height: 781px !important;
    background-image: url('../../../../../images/foto.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 558px;
    z-index: 2000;
}
.margin__special {
    margin-bottom: 137px;
}
.content-2-mb {
    padding: 161px 0 0 240px !important;
    margin-bottom: 0 !important;
}
.content-2-mb p {
    margin-bottom: 0 !important;
}
@media (max-width: 2560px) {
    .story-ellipse{
        width: 1000px;height: 1000px;
    }
}
@media (max-width: 1920px) {
    .story-main-content p:nth-child(1) {
        font-size: 36px;
    }
    .story-main-content p:nth-child(2),
    .story-main-content p:nth-child(3) {
        font-size: 24px;
    }
    .story-ellipse {
        width: 800px;
        height: 800px;
    }
}



@media (max-width: 1700px) {
    .story-ellipse {
        width: 800px;
        height: 800px;
    }
}
@media (max-width: 1440px) {
    .story-ellipse {
        width: 550px;
        height: 550px;
    }
}
@media (max-width: 1024px) {
    .story-ellipse {
        width: 300px;
        height: 300px;
    }
    .story-main-content p:nth-child(1) {
        font-size: 30px;
        width: 60%;
    }
    .story-main-content p:nth-child(2),
    .story-main-content p:nth-child(3) {
        font-size: 20px;
    }
    .story-header h1:nth-child(2) {
        margin-right: 30px;
    }
}
@media (max-width: 768px) {
    .story-main-content p:nth-child(1) {
      
    }
    .story-main-content p:nth-child(2),
    .story-main-content p:nth-child(3) {
        font-size: 18px;
    }
    .story-ellipse {
        width: 300px;
        height: 300px;
        right: 50px;
        top: 35%;
    }
    .story-main-content p:nth-child(2) {
        width: 50%;
    }
}
