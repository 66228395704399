.story-contact p:nth-child(1) {
    font-size: 18px;
    color: hsla(225, 48%, 90%, 0.384);
    text-transform: uppercase;
}
.story-contact p:nth-child(2) {
    font-size: 18px;
    color: #eec01b65;
    text-transform: uppercase;
}
.story-contact {
    padding-left: 20px;
}
.norm {
    text-transform: lowercase !important;
    font-size: 24px;
}
.hire-social {
    display: flex;
    max-width: 300px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 20px;
}
.hire-social a {
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fb.svg');
    background-repeat: no-repeat;
    content: '';
    width: 48px;
    height: 48px;
}
.hire-footer-right {
    display: flex;
    flex-direction: column;
    color: hsla(225, 48%, 90%, 0.384);
    padding-left: 20px;
}
.hire-footer-right div {
    display: flex;
}
.hire-footer-right div p:nth-child(1) {
    margin-right: 10px;
}
.fw_norm {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
}
.mt150px {
    margin-top: 150px !important;
}
.violetMobileMarg {
    bottom: 463px;
}
.posMob3{
    right: 30px;
    bottom: 473px;
}