.story-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 60px 60px 0 60px;
    top: 0;
    width: 100%;
    background: transparent;
    z-index: 100;
    position: absolute;
}
.header-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.header-left div:nth-child(1) {
    display: flex;
    text-transform: uppercase;
    margin-right: 235px;
}
.header-left div:nth-child(1) h1:nth-child(1) {
    color: #fff;
    font-weight: 300;
    padding-right: 15px;
    font-size: 36px !important;
    line-height: 1;
}
.header-left div:nth-child(1) h1:nth-child(2) {
    color: #eebf1b;
    font-weight: 400;
    font-size: 36px !important;
    line-height: 1;
}
.header-left div:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.header-left div:nth-child(2) a {
    color: #fff;
    text-transform: uppercase;
    margin-right: 36px;
}
.header-right, .personal-right{
    content: '';
    right: 0;
    background-image: url('../../../../../images/Logo.svg');
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
