.story-bottom {
    /* height: 150vh; */
    background-color: #071013;
    background-image: url('../../../../../images/Noise.svg');
    background-repeat: repeat;
    background-size: 100%;
}
.marques{
    width: 100vw;
    margin-top: 145px !important;
    margin-bottom: 140px;
}
.marquee-img,
.marquee-img-reverse {
    content: '';
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 220px;
    height: 280px;
    margin-right: 30px;
}
.marquee-1 {
    background-image: url('../../../../../images/firstMarquee/Shachtar.svg');
}
.marquee-2 {
    background-image: url('../../../../../images/firstMarquee/PUMA.svg');
}
.marquee-3 {
    background-image: url('../../../../../images/firstMarquee/Parimatch.svg');
}
.marquee-4 {
    background-image: url('../../../../../images/firstMarquee/Nestle.svg');
}
.marquee-5 {
    background-image: url('../../../../../images/firstMarquee/NAVI.svg');
}
.marquee-6 {
    background-image: url('../../../../../images/firstMarquee/McDonalds.svg');
}
.marquee-7 {
    background-image: url('../../../../../images/firstMarquee/Kasta.svg');
}
.marquee-8 {
    background-image: url('../../../../../images/firstMarquee/Chipsters.svg');
}
.marquee-10 {
    background-image: url('../../../../../images/secondMarquee/Avon.svg');
}
.marquee-20 {
    background-image: url('../../../../../images/secondMarquee/Hushme.svg');
}
.marquee-30 {
    background-image: url('../../../../../images/secondMarquee/Kite.svg');
}
.marquee-40 {
    background-image: url('../../../../../images/secondMarquee/Kite1.svg');
}
.marquee-50 {
    background-image: url('../../../../../images/secondMarquee/Seat.svg');
}
.marquee-60 {
    background-image: url('../../../../../images/secondMarquee/Uber.svg');
}
.marquee-70 {
    background-image: url('../../../../../images/secondMarquee/Uberglovo.svg');
}
.story-bottom-marquee {
    max-width: 100vw;
    overflow: hidden;
    margin-bottom: 55px;
}
.story-bottom-marquee-reverse {
    max-width: 100vw;
    overflow: hidden;
}
.marquee-div {
    display: flex;
    width: 200vw;
    animation: marquee 18s linear infinite;
}
.marquee-div-reverse {
    display: flex;
    width: 200vw;
    animation: marquee-reverse 18s linear infinite;
}
.story-bottom-marquee span {
    align-items: center;
    width: 100vw;
    display: flex;
    justify-content: space-around;
}
.story-bottom-marquee-reverse span {
    align-items: center;
    width: 100vw;
    display: flex;
    justify-content: space-around;
}
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}
@keyframes marquee-reverse {
    0% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.mForFooter{

}
.story-footer {
    background: rgb(0, 0, 0);
    padding: 60px 240px;
    position:relative;
    bottom: 0 !important;
    left: 0;
    width: 100vw;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.story-footer-left {
    display: flex;
    flex-direction: row;
}
.story-footer-left a {
    color: #fff;
    margin-right: 60px;
    min-height: 24px;
    font-size: 18px;
    font-weight: 300;
    color: #EDF5FC;
    text-transform: uppercase;
    transition: all 0.3s;
}
.story-footer-left a:last-child {
    margin-right: 0 !important;
}
.story-footer-left a:hover {
    transition: all 0.3s;
    border-bottom: 1px solid #eebf1b;
}
.story-footer-middle {
    content: '';
    background-image: url('../../../../../images/Logo.svg');
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.story-footer-right {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    font-size: 18px;
    color: #dae0f2;
    opacity: 0.6;
}
.story-footer-right div {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}
.story-footer-right div p {
    text-transform: uppercase;
}
.story-footer-right div p:nth-child(2) {
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: underline;
    margin-right: 81px;
    margin-left: 5px;
}
.story-footer-right p:nth-child(2) {
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: underline;
    color: #dae0f2;
}
.story-footer-right p:nth-child(2) a {
    color: #dae0f2;
    text-decoration: underline;
}
@media (max-width: 800px) {
    .story-footer {
        position: relative;
        flex-direction: column;
    }
    .story-footer-left,
    .story-footer-right {
        flex-direction: column;
    }
    .story-footer-left a {
        margin: 0 !important;
        text-align: center;
        padding-bottom: 20px;
    }
    .violet_on_hover {
        margin: 0 !important;
    }
    .story-footer-right {
        text-align: center;
    }
}

.content-2{
    width: 80%;
}