.storyLayout {
    overflow-y: auto;
    position: absolute;
    bottom: 0;
    width: calc(100vw);
    height: 100vh;
    z-index: 2000;
    top: 0;
    opacity: 1 !important;
    transition: clip-path 0.3s ease;
    animation: storyShow;
    animation-timing-function: cubic-bezier(0.67, 0.51, 0.51, 0.91);
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    transition: clip-path 0.4s;
    -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 3% 95.5%);
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 3% 95.5%);
}
.storyFullScreen {
    z-index: 2000;
    -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
    clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
    transition: clip-path 0.3s;
    animation: toFullScreenStory 0.3s cubic-bezier(0.67, 0.51, 0.51, 0.91)
        forwards;
    transition: clip-path 0.2s;
}
@keyframes toFullScreenStory {
    0% {
        -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
        clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
    }
    100% {
        -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
    }
}
.reversedLayoutStory {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    opacity: 1 !important;
    left: 0;
    transition: clip-path 0.3s ease;
    animation: storyShowBack;
    animation-timing-function: cubic-bezier(0.67, 0.51, 0.51, 0.91);
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
@keyframes storyShowBack {
    0% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
        clip-path: polygon(18% 0, 100% 0, 100% 100%, 7% 92%);
    }
    35% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
        clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
    }
    100% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
        clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
    }
}
@keyframes storyShow {
    0% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
        clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
    }
    35% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
        clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
    }
    100% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 3% 95.5%);
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 3% 95.5%);
    }
}
@media (max-width: 1920px) {
}
.storyBtn {
    font-size: 13px;
    position: absolute;
    top: 60px;
    z-index: 2000;
    right: 60px;
}
.pos2 {
    top: 70px;
    right: 70px;
}
.storyBlock {
    transition: 0.3s ease;
    z-index: 2000;
    color: #fff;
    overflow: visible !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: 300;
    margin-top: 228px;
    margin-left: 481px;
}
.storyBlock h3 {
    z-index: 2000;
    font-size: 40px;
    font-weight: 300;
}
.storyBlock a,
.storyBlock p {
    z-index: 2000;
    margin: 20px 0 10px 0;
    color: #fff;
    text-transform: uppercase;
}
.storyBlock div {
    z-index: 3000;
    display: flex;
    flex-direction: column;
}
.storyBlock > div {
    padding-left: 190px;
}
.casesArrPadding {
    margin-top: 70px !important;
}

.storyBlock a:first-child {
    margin-top: 0 !important;
}

@media (max-width: 1900px) {
    @keyframes storyShow {
        0% {
            opacity: 1 !important;
            -webkit-clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
            clip-path: polygon(100% 0, 100% 0, 100% 0, 7% 92%);
        }
        35% {
            opacity: 1 !important;
            -webkit-clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
            clip-path: polygon(84% 0, 100% 0, 100% 14%, 7% 92%);
        }
        100% {
            opacity: 1 !important;
            -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 5% 92%);
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 5% 92%);
        }
    }
}