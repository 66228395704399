.preloader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1000203920392092039023;
    height: 100vh;
    object-fit: contain;
}
.displayed{
    display: block !important;
}