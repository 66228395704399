.personal-page {
    position: relative;
    width: 100vw;
    background-color: #071013;
}
.backVideoNext {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.personal-page .next-project .next-proj {
    transition: all 0.4s;
}
.personal-page .next-project:hover .next-proj {
    color: #fff !important;
}
.backVideoNext video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.personal-header {
    display: flex;
    padding: 60px 60px 0;
    justify-content: space-between;
}
.personal-left,
.personal-left div {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.personal-left-div {
    display: flex;
}
.personal-left div:first-child {
    margin-right: 361px;
}
.personal-left .link {
    width: auto !important;
    height: 23px !important;
}
.fixedPersonalBtn {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 90%;
}
.personal-left {
    justify-content: flex-start;
}
.personal-left h1:nth-child(1) {
    margin-right: 7px;
}
.personal-left div:nth-child(1) h1,
.link {
    font-weight: normal;
    color: #edf5fc;
    font-size: 18px;
    line-height: 23.04px;
}
.link:nth-child(2) {
    margin-left: 35px;
}
.header-title {
    padding: 60px 0 0 60px;
    font-size: 70px;
    color: #edf5fc;
    font-weight: normal;
}
.header-title p:first-child {
    position: relative;
    left: -7px;
}
.header-desc {
    padding: 0 0 74px 60px;
    font-size: 24px;
    font-weight: lighter;
    color: #edf5fc;
}
.text-block {
    position: relative;
    z-index: 2000;
    display: flex;
    box-shadow: 0px -26px 20px 25px rgba(7, 16, 19, 0.99);
    height: auto !important;
    width: 100vw;
    background-image: url('../../../images/X.svg');
    background-repeat: space;
    background-position: center;
    background-size: 8%;
    background-color: #071013;
    color: #edf5fc;
    justify-content: space-between;
    padding: 200px 240px;
}
.text-block-div {
    display: flex;
    padding-top: 0 !important;
    margin-right: 0 !important;
    width: 100%;
}
.text-block-div .text-block-p:first-child {
    
}
.heightClass3 div:nth-child(1) {
    padding-top: 0 !important;
}
.text-block-special-bg {
    background-image: none !important;
    background-color: #071013;
}
.text-block-p {
    font-size: 24px;
}
.text-block-p:first-child {
    flex-basis: 70%;
    padding-right: 35%;
}
.text-block-p:last-child {
    flex-basis: 30%;
}
.desc {
    width: 100%;
    text-transform: none !important;
    margin-top: 60px;
}
.desc p {
    text-transform: none !important;
}
.desc p:nth-child(2) {
    font-weight: lighter;
    font-weight: 15px;
    color: #dae0f2;
}
.text-block div:nth-child(2) {
    font-weight: lighter;
}
.text-block div:nth-child(1) {

    font-weight: normal;
    text-transform: uppercase;
}
.text-block div small {
    text-transform: none !important;
}
.personal-image {
    content: '';
    width: 100vw;
    height: 80vh;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../images/personal.svg');
}
.personalSlider {
    position: relative;
}
.personal-slider {
    width: 100vw;
    height: 50vh;
}
.personal-slider div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
.slide1 {
    background-image: url('../../../images/image\ 8.svg');
}
.slide2 {
    background-image: url('../../../images/image\ 9.svg');
}
.slide3 {
    background-image: url('../../../images/image\ 10.svg');
}
.slide4 {
    background-image: url('../../../images/image\ 9.svg');
}
.slide5 {
    background-image: url('../../../images/image\ 8.svg');
}
.prev-slider::after,
.next-slider::before {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 96px;
    height: 96px;
    border-radius: 50%;
    content: '';
}
.prev-slider,
.next-slider {
    z-index: 2000;
    background-repeat: no-repeat;
    background-position: 8px 8px !important;
    pointer-events: visible;
    border: none;
    position: absolute;
    width: 48px;
    height: 48px;
    content: '';
    border-radius: 50%;
    bottom: 40%;
    background-color: #071013;
    animation: pulseBtnSlider 2s infinite;
    transition: transform ease 0.1s;
}
.next-slider {
    background-position-x: 24px !important;
}
@keyframes pulseBtnSlider {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    50% {
        box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
.prev-slider {
    background-position-y: bottom;
    left: 60px;
    background-image: url('../../../images/elipse.png');
}
.next-slider {
    background-position-y: bottom;
    background-position-x: right;
    right: 60px;
    background-image: url('../../../images/elipse_r.png');
}
.prev-slider-video {

    background-image: url('../../../images/elipse_w.png') !important;
}

.next-slider-video {
    background-image: url('../../../images/elipse_r_w.png') !important;
}
.activeBtn {
    transform: scale(1.5);
    transition: transform ease 0.2s;
}
.contact-block {
    width: 100% !important;
    background-color: #071013;
    padding: 240px;
    position: relative;
}
.contact-block .storyBtn {
    top: 120px;
    right: 120px;
}
.mt100 {
    margin-bottom: 100px;
}
.main-contact {
    font-size: 40px;
    color: #edf5fc;
    text-transform: uppercase;
}
.line-block-position-default {
    font-size: 20px !important;
}
.position {
    color: #dae0f270 !important;
    font-size: 20px;
    text-transform: none !important;
    font-weight: lighter;
}
.line-block {
    margin-bottom: 50px;
    display: flex;
}
.line-block div {
    margin-right: 3%;
}
.line-block div p {
    font-size: 30px;
    color: #edf5fc;
    text-transform: uppercase;
}
.big-block {
    margin-top: 70px;
    margin-bottom: 70px;
}
.line-block-contact-big:nth-child(1) {
    font-size: 45px !important;
}
.next-project {
    position: relative;
    width: 100vw !important;
    background-image: url('../../../images/Showreel.svg');
    background-position: center;
    background-size: 105%;
    background-repeat: no-repeat;
    overflow-x: hidden !important;
}
.next-project .storyBtn {
    position: absolute;
    top: auto !important;
    bottom: 96px;
    right: 240px;
}
.info-block {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: auto;
    padding-top: 0;
    padding: 180px 240px;
    color: #dae0f2;
}
.info-block h1 {
    font-size: 120px;
    font-weight: 450;
}
.info-block p {
    font-size: 24px;
}
.next-proj {
    padding-top: 273px;
    color: #dae0f279;
}
.contact-block {
    overflow: visible !important;
}
.gradientBtnContact {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    position: relative; 
    background: linear-gradient(125deg, #7344f4 10%, #eec01b 50%, #7344f4 90%);
    background-size: 600% 600%;
    box-shadow: 0 0 0 0 #eec01b;
    animation: gradient 5s ease infinite;
}
.pos4 {
    top: 10px;
    left: 9px;
    width: 90px !important;
    height: 90px !important;
}
.pos5 {
    top: auto !important;
    bottom: 108px;
    right: 252px;
  }
.posStickBtn {
    width: 110px;
    height: 110px;
    right: 240px;
    top: 240px;
    position: absolute;
}
.gradientBtnContact p {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #dae0f2;
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.pStoryBtn p {
    padding-top: 0 !important;
}
@media (min-width: 1920px) {
    .header-title {
        font-size: 120px;
    }
    .text-block-p {
        font-size: 36px;
    }
    .contact-block {
        width: 89vw;
        margin: auto;
    }
    .info-block {
        width: 80%;
    }
}

@media (min-width: 1920px) {
    .cases-Array {
        width: 550px;
        height: 430px;
    }
    .casesArrayItem {
        height: 55px;
        font-size: 45px;
    }
    .casesArrayItem:hover::before {
        z-index: 2000;
        position: absolute;
        transition: 0.3s ease-in-out;
        left: -53px;
        content: '';
        width: 53px;
        height: 53px;
    }
}
.slick-slide {
    transition: all 0.5s ease-in-out;
}
.sliderTrackMoved {
    padding-right: 0;
    animation: movingSliderTrack 0.5s ease-in-out forwards;
}
@keyframes movingSliderTrack {
    from {
        padding-right: 0;
    }
    to {
        padding-right: -50px;
    }
}
#personal-slider .personalSlider .slick-slide:hover {
    position: relative;
    width: 30vw !important;
    overflow:hidden;
    transition: all 0.5s ease-in-out;
}
.heightClass{
    height: 62vh;
}
.approach{
    padding-top: 0 !important; 
    margin-top: 0 !important;
    text-transform: none;
}
.PreviewImages {
    margin-bottom: -4px;
}
.PreviewImages img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
}
.contactBTNMY {
    top: 240px;
    right: 240px;
}
.pos6 {
    top: 250px;
    right: 252px;
}