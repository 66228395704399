.caseLinkIMP{
    
    z-index: 120390 !important;
}
.caseLinkIMP .casesBtn{
    font-size: 12px !important;
}
.btm0{
    bottom: 120px !important;
    right: 36px;
    width: 96px !important;
    height: 96px !important;
}
.swiper-item-p{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
}