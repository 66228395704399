.hireBLockFull {
    height: auto !important;
}
.hireBtnViolet:hover {
    border: none !important;
    background-color: #fff;
    animation: pulseclicked 2s ease;
    background-image: url('../../../images/CloseBlack.svg');
}
.hireBtnPage p{
    padding-top: 0 !important;
}
.hireBtnPage{
    position: absolute !important;
    bottom: -48px !important;
    right: 60px !important;
}
.hireBtnPoA .pos3 {
    bottom: -39px !important;
    right: 70px !important;
  }
/* .hirefullPage{
    overflow-y: hidden !important;
    height: calc(84vh + 10px) !important;
} */
.hireBtnViolet{
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    background-color: #7344f4;
    width: 85px;
    height: 85px;
    background-image: url('../../../images/CloseWhite.svg');
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2002;
    position: absolute;
    border: none !important;
    bottom: 40px;
    left: 60px;
    border: none;
    animation: pulsebackground 2s ease;
}
.hireBloack{
    width: 100vw;
    height: 100vh;
}
.hireBtnPoA{
    position: relative;
}
@media (max-width: 1920px) {
    .hireBLockFull {
        margin-left: 350px;
        margin-top: 220px;
    }
}
@media (max-width: 1600px) {
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 35px !important;
    }
    .hireBLockFull {
        margin-left: 21%;
        margin-top: 100px;
    }
}
@media (max-width: 1341px) {
    .hireBLockFull {
        margin-left: 22% !important;
        margin-right: auto;
      }
}
@media (max-width: 1200px) {
    .hireBLockFull {
        margin-left: 14% !important;
        margin-right: auto;
      }
}
@media (max-width: 1060px) {
    .hireBLockFull {
        margin-left: 60px !important;
        margin-right: auto;
      }
}
@media (max-width: 900px) {
    .hireBLockFull {
        margin-top: 120px !important;
    }
    .hireBtnPage {
        position: absolute !important;
        bottom: 60px !important;
        right: 60px !important;
    }
    .hireBtnPoA .pos3 {
        bottom: 69px !important;
        right: 70px !important;
    }
    .hireBLockFull .hireBLock__right__bottom {
        margin-top: 30px;
        margin-bottom: 50px;
    }
}
@media (max-width: 608px) {
    .hireBLockFull {
        margin-top: 145px !important;
    }
}