.LoadMore {
    font-size: 24px !important;
}
#MainVideoPreviewHome_ {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.casesLayout {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: #000;
    animation: showLayout forwards;
    animation-timing-function: cubic-bezier(0.11, 0.58, 0.56, 0.61);
    animation-duration: 0.7s;
    animation-delay: 0.2s;
    clip-path: polygon(50% 10%, 50% 93%, 50% 100%, 50% 100%, 50% 93%);
}
@keyframes showLayout {
    0% {
        clip-path: polygon(50% 10%, 50% 93%, 50% 100%, 50% 100%, 50% 93%);
    }
    30% {
        clip-path: polygon(50% 10%, 100% 100%, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 10%, 100% 24%, 100% 100%, 0 100%, 0 38%);
    }
}
.casesFullScreen {
    z-index: 2000;
    clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
    transition: clip-path 0.3s;
    animation: toFullScreen forwards;
    animation-timing-function: cubic-bezier(0.11, 0.58, 0.56, 0.61);
    animation-duration: 0.4s;
}
@keyframes toFullScreen {
    0% {
        clip-path: polygon(50% 10%, 100% 24%, 100% 100%, 0 100%, 0 38%);
    }
    /* 50% {
        clip-path: polygon(25% 5%, 75% 12%, 100% 100%, 50% 100%, 0 72%);
    } */
    100% {
        clip-path: polygon(
            50% 0%,
            100% 0%,
            100% 100%,
            0% 100%,
            0% 0%
        ) !important;
    }
}
.reversedLayout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    animation: showLayoutBack forwards;
    animation-timing-function: cubic-bezier(0.11, 0.58, 0.56, 0.61);
    animation-duration: 0.5s;
    clip-path: polygon(50% 10%, 50% 93%, 50% 100%, 50% 100%, 50% 93%);
}
.pos3 {
    bottom: 70px;
    right: 70px;
}

@keyframes showLayoutBack {
    0% {
        clip-path: polygon(50% 10%, 100% 24%, 100% 100%, 0 100%, 0 38%);
    }
    60% {
        clip-path: polygon(50% 10%, 100% 100%, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        display: none;
        clip-path: polygon(50% 10%, 50% 93%, 50% 100%, 50% 100%, 50% 93%);
    }
}
.Tilt {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
}
#casesArrayItemId {
    position: absolute !important;
}
#casesLayoutElem {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
#parallaxImageBlock {
    position: absolute !important;
    top: -4vh;
    left: -4vw;
    width: 120vw;
    height: 120vh;
    z-index: 20000 !important;
    perspective: 1000px;
    -webkit-backface-visibility: hidden;
    transition: transform ease 0.2s !important;
    overflow: hidden;
}
.casesBtn {
    z-index: 100000000001 !important;
    position: absolute;
    bottom: 60px;
    right: 96px;
}
.casesBtn p {
    font-weight: 600;
}
.full-mobile {
    margin-top: 100px !important;
    bottom: 350px !important;
    right: calc(37%) !important;
}
.casesTextBlock {
    z-index: 40000;
    position: absolute;
    bottom: 60px;
    left: 60px;
    color: #fff;
    font-weight: lighter;
    text-transform: uppercase;
}
.casesArray {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    color: #fff;
    width: auto;
    bottom: 60px;
    left: calc(60%);
    font-size: 36px;
    z-index: 2020202020;
    font-weight: lighter;
    overflow: visible;
    transform: none !important;
}
.casesArray div:hover {
    pointer-events: visible;
}
.casesArray div {
    transform: none !important;
    margin-top: 25px;
    font-weight: 400;
    z-index: 2020202020;
    overflow: visible;
    height: 36px !important;
    align-items: center;
    padding: 5px 18px;
    transition: 0.3s ease-in-out;
    display: flex;
    position: relative;
    width: auto;
}
.arrowToTop {
    z-index: 2000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    width: 24px;
    height: 24px;
    background-image: url('../../images/▼arrowTop.svg');
}

.casesArrow {
    z-index: 2000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    width: 24px;
    height: 24px;
    background-image: url('../../images/arrow.svg');
    margin-bottom: 24px !important;
}
.arrowToTop {
    margin-top: 24px;
}
.casesMain {
    position: absolute;
    font-weight: normal;
    font-size: 40px;
    z-index: 40000;
    color: #fff;
    left: 60px;
    bottom: 290px;
    transition: 0.3s ease-in-out;
}
.casesMain p {
    font-weight: lighter;
    font-size: 25px;
    transition: 0.3s ease-in-out;
}
.casesMain p:last-child {
    font-size: 24px;
    font-weight: 200;
    transition: 0.3s ease-in-out;
}
.mcDonalds:hover {
    background-color: #f1bb00;
    color: #000;
    transition: ease-in-out !important;
}
.casesMain article p {
    font-weight: lighter !important;
}
.cases_icon {
    position: absolute;
    transition: ease-in-out;
    left: -36px;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition:  ease-in-out !important;
    opacity: 0;
    visibility: hidden;
}
.mcDonalds:hover .cases_icon {
    opacity: 1;
    visibility: visible;
}
.puma:hover {
    background-color: #000;
    color: #fff;
    transition: ease-in-out !important;
}
.puma:hover::before {
   
}
.flint:hover::before {
   
}
.littlebig:hover::before {
    
}
.parimatch:hover::before {
    
}
.navi:hover::before {
    
}

@keyframes animUniversal {
    to {
        background: transparent;
    }
}
@keyframes animBackground {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}
.mcBack {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    animation: animBackground 0.2s ease-in-out forwards;
    top: 0;
    left: 0;
    position: relative;
    overflow: hidden !important;
    transition: all 0.4s ease !important;
}
.pBack {
    background-image: url('../../images/pumaback.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2000;
    opacity: 0;
    animation: animBackground 0.2s ease-in-out forwards;
    top: 0;
    left: 0;
    overflow: hidden !important;
    transition: all 0.4s ease !important;
}

.casesMain {
    font-weight: 600;
    animation: translateX 0.1s ease-in-out;
}
.casesMain p:nth-child(1) {
    font-size: 70px;
}
.casesMain article {
    position: relative;
    left: -6px;
}
@keyframes translateX {
    0% {
        transform: translateX(-300px);
    }
    100% {
        transform: translateX(0px);
    }
}
.cases-full-array {
    width: 100% !important;
    font-size: 36px;
    position: relative;
}
.cases-full-array div a div {
    width: 100%;
    color: #fff;
    text-align: center;
    height: 22vh !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array p {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.cases-full-array > div > div {
    background-color: rgba(0, 0, 0, 0.3);
}
.cases-full-array > div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(2) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(3) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(4) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(5) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(6) {
    font-size: 20px;
}
.cases-full-array > div:nth-child(7) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(8) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cases-full-array > div:nth-child(9) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.mb100 {
    margin-bottom: 100px !important;
}
.caseBtnSlider {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: none;
    text-align: center;
    position: absolute;
    top: 36px;
    left: 36px;
    background: #f1bb00;
    color: #000;
    z-index: 2000;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}
.caseBtnSlider p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
@keyframes destroyBack {
    0% {
        opacity: 1;
    }
    100% {
        background-image: none;
        opacity: 0.000000001;
    }
}
.mcBackdestroyed,
.pBackdestroyed {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    transition: all 0.3s ease !important;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    backdrop-filter: none;
    animation: destroyBack 1s ease forwards;
}
.mcBackdestroyed {
    background-image: url('../../images/Showreel.svg');
}
.pBackdestroyed {
    background-image: url('../../images/pumaback.svg');
}
.pos33 {
    bottom: 70px;
    right: 106px;
}
.posMob2{
    right: 30px;
    bottom: 0;
}
.posMob4{
    bottom: 360px !important;
    right: calc(38.5%) !important;
}
.textSpecial {
    color: #EEBF1B;
}