* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: none;
    font-family: 'Futura PT';
}
/* .firstCircle {
    cursor: url('../images/CursorHovered.svg') 20 10, pointer !important;
} */
.break {
    flex-basis: 100% !important;
    height: 0 !important;
}
a {
    text-decoration: none;
}
li {
    list-style: none;
}
html {
    overflow-x: hidden !important;
    background-color: #000;
}
.slick-arrow {
    display: none;
}
.blackBack {
    display: none;
}
.header-right,
.personal-right {
    right: 55px !important;
}

.animated-logo {
    position: absolute;
    z-index: 2000;
}
.animated-logo-X {
    position: absolute;
    z-index: 1999;
    top: 60px;
    right: 60px;
    width: 36px;
    height: 36px;
    color: transparent;
    background-image: linear-gradient(90deg, #5703f4, #ecb130, #cf5feb, #5703f4);
    background-size: 400%;
    transition: 0.5s;
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    filter: blur(25px);
    -webkit-backdrop-filter: blur(0px) !important;
    opacity: 1;
    animation: animate 12s linear infinite;
}
@keyframes animate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}
@keyframes neon {
    from {
        text-shadow: 0 0 6px rgba(250, 169, 239, 0.98),
            0 0 54px rgba(250, 169, 239, 0.98),
            0 0 1px rgba(158, 110, 247, 0.52),
            0 0 35px rgba(158, 110, 247, 0.52), 0 0 3px rgba(245, 169, 29, 1),
            0 0 54px rgba(245, 169, 29, 1);
    }
    to {
        text-shadow: 0 0 6px rgba(250, 169, 239, 0.98),
            0 0 54px rgba(250, 169, 239, 0.98),
            0 0 12px rgba(158, 110, 247, 0.52),
            0 0 44px rgba(158, 110, 247, 0.52),
            0 0 38px rgba(245, 169, 29, 0.88), 0 0 100px rgba(245, 169, 29, 1);
    }
}
.cube {
    transition: all 0.3s ease !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    animation: animBackground 0.4s linear;
    height: 100vh;
    pointer-events: none;
    z-index: 0;
}
.undefined {
    color: #000;
}
.slick-arrow {
    display: none !important;
}
.page {
    position: absolute;
}

.hover__special__menu:hover {
    transition: all 0.3s;
    border-bottom: 1px solid #eebf1b;
}

.schar__ {
    color: #eebf1b;
    display: inline !important;
}

.casesArray div:hover .schar__ {
    color: #fff !important;
}

.casesArray span:hover .schar__ {
    color: #fff !important;
}

.casesArray .casesArrayItem:hover span .schar__ {
    color: #fff !important;
}

.displayNoneMain {
    display: none !important;
}

#prevBtnSlider_video {
    top: 60px !important;
    right: 168px !important;
    left: auto !important;
    animation: none !important;
    background-color: rgba(237, 245, 252, 0.12);
}

#nextBtnSlider_video {
    top: 60px !important;
    right: 60px !important;
    left: auto !important;
    animation: none !important;
    background-color: rgba(237, 245, 252, 0.12);
}
.story-footer-left-mobile {
    display: none !important;
}

.unselectable {
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important;   /* Chrome/Safari/Opera */
    -khtml-user-select: none !important;    /* Konqueror */
    -moz-user-select: none !important;      /* Firefox */
    -ms-user-select: none !important;       /* Internet Explorer/Edge */
    user-select: none !important;           /* Non-prefixed version, currently */
}



