.video__filter {
    filter: blur(10px);
}

.special__overlay__x {
    background-image: url('../../images/X.svg');
    background-repeat: repeat;
    background-position: top center;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.special__noise {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/bg-noise.gif');
    opacity: 0.2;
}

.special__noise__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
}

.showreel {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    left: 0;
    top: 0;
    object-fit: cover;
}
.no_overflow {
    overflow-y: hidden;
}

.mainPageSelector {
    opacity: 0;
    animation: unFocusFocus 2s forwards;
    overflow: hidden;
}
@keyframes unFocusFocus {
    0% {
        opacity: 0;
        filter: blur(1.2rem);
    }
    25% {
        opacity: 0;
        filter: blur(0.6rem);
    }
    50% {
        filter: blur(1.2rem);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}
@keyframes unFocusFocusMobile {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.toner {
    background: rgb(255, 255, 255, 0);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.8432948179271709) 100%
    );
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -9;
    left: 0;
    top: 0;
}
#polyline {
    position: absolute;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100000;
    top: 0;
    left: 0;
    pointer-events: none;
}
#polyline1 {
    position: absolute;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100000;
    top: 0;
    left: 0;
    pointer-events: none;
}
#polyline2 {
    position: absolute;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100000;
    top: 0;
    left: 0;
    pointer-events: none;
}
#overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9998;
}
#overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9998;
}
#overlay3 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9998;
}
.displayNone {
    z-index: -10000;
    width: 0vw;
    height: 0vh;
    display: none !important;
    transform: translate(9999px);
    opacity: 0;
}
.black {
    background-color: #000;
}
.main {
    padding: 60px;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    color: #fff;
    overflow-y: hidden !important;
}
.main::before {
    content: '';
    z-index: 6;
    width: 100vw;
    height: 100vh;
    top: 0;
    opacity: 0.06;
    left: 0;
    background-image: url('../../images/bg-noise.gif');
    position: absolute;
    background-repeat: repeat;
    background-size: 10%;
}
.noise::before {
    content: '';
    width: 100vw;
    height: 100vh;
    top: 0;
    opacity: 0.06;
    left: 0;
    z-index: 2000;
    background: #00000007;
    background-image: url('../../images/bg-noise.gif');
    position: absolute;
    background-size: cover;
}
.main__logo {
    position: absolute;
    right: 60px;
    top: 60px;
    background-image: url('../../images/Logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 52px;
    height: 60px;
}
.main__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.main__bottom {
    align-items: center;
    margin-bottom: 28px;
}
.main__bottom div {
    max-width: 960px !important;
    margin: 0 auto;
}
.main__bottom__video {
    width: 50%;
    margin: auto;
    border: none;
    height: 1px;

    content: '';
    background-color: rgb(238, 245, 252, 0.24);
}
.progress-done {
    opacity: 1;
    background-color: #eebf1b;
    width: 0%;
    margin: auto;
    height: 1px;
    content: '';
    transition: all 0.1s ease-in-out;
}
@keyframes progressBar {
    0% {
        width: 0%;
    }
    98% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

.line-animation-wrapper {
    position: absolute;
    top: 6%;
    left: 50%;
    z-index: 9999;
    color: #fff;
}

.line-animation-wrapper-left {
    overflow: hidden;
    left: 0 !important;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);  
}

.line-animation-right {
    transition: height 3s ease-in-out;
}

.line-animation-left {
    transition: height 2s ease-in-out;
}

.line-animation-wrapper-right, .line-animation-wrapper-right_story, .line-animation-wrapper-left_story, 
.line-animation-wrapper-right_hire, 
.line-animation-wrapper-left_hire {
    overflow: hidden;
}

svg path {
    -webkit-transition: d 0.5s ease-in-out;
    -moz-transition: d 0.5s ease-in-out;
    transition: d 0.5s ease-in-out;
}

.canvas-line-animation {
    height: 0;
}

.canvas-line-animation_story {
    height: 0;
}

.canvas-line-animation_story_vertical {
    width: 0;
}

.canvas-line-animation_hire {
    height: 0;
}

.canvas-line-animation_hire_vertical {
    width: 0;
}

.line-animation-wrapper_story {
    position: fixed;
    z-index: 9999;
    color: #fff;
}

.line-animation-wrapper_hire {
    position: fixed;
    z-index: 9999;
    color: #fff;
}

.line-animation-right_story {
    transition: height 2s ease-in-out;
}

.line-animation-left_story {
    transition: width 1.3s ease-in-out;
}

.line-animation-right_hire {
    transition: height 2s ease-in-out;
}

.line-animation-left_hire {
    transition: width 1.3s ease-in-out;
}

.line-animation-wrapper-right_story {
    bottom: 90px;
    transform: translateY(100%);
    left: 90px;
}

.line-animation-wrapper-left_story {
    bottom: 110px;
    left: 62px;
}

.line-animation-wrapper-right_hire {
    bottom: 90px;
    transform: translateY(100%) scale(-1, 1);
    right: 90px;
}

.line-animation-wrapper-left_hire {
    bottom: 80px;
    right: 62px;
    transform: scale(-1, 1);
}
