.cases-page {
    width: 100vw;
    height: 100vh;
    background: #000000;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    transition: all ease 0.3s;
    opacity: 0;
    overflow-x: hidden !;
    animation: showCasesPage 0.4s ease forwards;
    position: relative;
}
.cases-page .story-header {
    z-index: 20001;
}
#MainVideo_ {
    width: 115%;
    z-index: 2000;
    position: absolute;
    left: 0;
    top: 0;
}
.parallaxImageBlock_size {
    width: 100%;
    height: 100%;
    
}
.parallaxImageBlock_shum {
    background-image: url('../../../images/bg-noise.gif');
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2001;
    opacity: 0.1;
    background-size: 12%;
}
.parallaxImageBlock_overlay {
    background-color: #000000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2002;
    opacity: 0.2;
}
@keyframes showCasesPage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.cases-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cases-page .casesMain article p {
    font-weight: lighter !important;
}
.storyHeader {
    position: absolute;
}
.cases-Array {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    height: auto !important;
    align-items: flex-start;
    width: auto !important;
    bottom: 60px;
    z-index: 100000;
    font-weight: 300;
    left: calc(42%);
}
.casesArrayItem {
    padding: 0 12px !important;
    position: relative !important;
    font-size: 36px !important;
    font-weight: 400;
    text-align: left;
    width: auto !important;
    margin-top: 0;
    margin-bottom: 18px;
    z-index: 2000;
    overflow: visible;
    display: flex !important;
    align-items: center !important;
    height: 36px !important;
}
.casesArrayItem article {
    display: block;
    padding-top: 0 !important;
    
    opacity: 0;
    transform: translateX(-100%);
    animation: lesenka 0.4s ease-in forwards;
}
@keyframes lesenka {
    0%{
        transform: translateX(-100%);
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0%);
    }
}
.pos32{
    right: 50px;
    bottom: 50px;
}
/* MC */
.hush-MC:hover {
    z-index: 2000;
    transition: 0.3s ease-in-out;
}

.hush-MC:hover .schar__ {
    color: #fff !important;
}

.hush-MC:hover .cases_icon {
    visibility: visible;
    opacity: 1;
}
.hush-MC:hover:active .cases_icon {
    visibility: visible;
    opacity: 1;
}
.cases_icon_show {
    visibility: visible !important;
    opacity: 1 !important;
}
.mcHoveredBack {
    position: absolute;
    top: 0;
    z-index: 22000;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    animation: showMcHoveredBack 0.2s forwards linear;
    transition: all 0.4s ease-out !important;
}
/* PUMA */
.hush-Puma:hover {
    z-index: 2000;
    background-color: #071013;
    color: #EDF5FC;
    transition: 0.3s ease-in-out;
}

.hush-Puma:hover .schar__ {
    color: #fff !important;
}

.hush-Puma:hover::before {

}
.PumaHoveredBack {
    position: absolute;
    top: 0;
    z-index: 22000;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('../../../images/pumaback.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    animation: showMcHoveredBack 0.2s forwards linear;
    transition: all 0.4s ease-out !important;
}



.transition-background {
    position: absolute;
    top: 0;
    z-index: 22000;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    animation: showMcHoveredBack 0.2s forwards linear;
    transition: all 0.4s ease-out !important;
}
@keyframes showMcHoveredBack {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.casesTextBlock p {
    font-size: 18px;
}

@keyframes myAnim {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

.casesBtnPage {
    right: 40px !important;
    bottom: 40px !important;
}
.cases-Array > div:first-child {
    margin-right: 138px !important;
}

.cases-Array > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.cases-page .universalBack {
    -webkit-backface-visibility: hidden;
    -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1);
    transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1);
    top: calc(-17vh);
    left: calc(-2vw);
    width: calc(119vw);
    height: calc(127vh);
}

.cases-page .pos32 {
    z-index: 22222 !important;
}

.cases-page .casesMain {
    position: absolute !important;
    top: calc(19%) !important;
    margin-top: 0 !important;
}

@media (max-width: 2560px) {
    .cases-page .universalBack {
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        top: calc(-20vh) !important;
        left: calc(2vw) !important;
        width: calc(119vw) !important;
        height: calc(137vh) !important;
      }
}
@media (max-width: 2260px) {
    .cases-page .universalBack {
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        top: calc(-26vh) !important;
        left: calc(0vw) !important;
        width: calc(119vw) !important;
        height: calc(137vh) !important;
    
    }
}
@media (max-width: 1950px) {
    .cases-page .universalBack {
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        top: calc(-17vh) !important;
        left: calc(-2vw) !important;
        width: calc(119vw) !important;
        height: calc(127vh) !important;
    }
    .cases-Array {
        left: calc(33%) !important;
    }
}
@media (max-width: 1760px) {
    .cases-page .universalBack {
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        top: calc(-20vh) !important;
        left: calc(-3vw) !important;
        width: calc(119vw) !important;
        height: calc(127vh) !important;
    }
}

@media (max-width: 1560px) {
    .cases-page .universalBack {
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(20deg) rotateX(-5deg) scale3d(1, 1, 1) !important;
        top: calc(-20vh) !important;
        left: calc(-6vw) !important;
        width: calc(119vw) !important;
        height: calc(127vh) !important;
    }
    .cases-page-slider {
        
    }
    .cases-Array {
        bottom: 220px !important;
        left: calc(50%) !important;
    }
}
@media (max-width: 1560px) and (max-height: 1100px) {
    .cases-Array {
        bottom: 172px !important;
        left: calc(30%) !important;
    }
}
@media (max-width: 1245px) {
    .casesArrayItem {
        font-size: 25px !important;
    }
    .cases-Array > div:first-child {
        margin-right: 65px !important;
    }
    .cases-Array {
        bottom: 150px !important;
        left: calc(50%) !important;
    }
    .cases-page .casesMain p:nth-child(1) {
        font-size: 40px;
    }
}
@media (max-width: 700px) {
    .casesArrayItem {
        padding: 0 12px !important;
        position: relative !important;
        font-size: 20px !important;
        font-weight: 400;
        text-align: left;
        width: auto !important;
        margin-top: 0;
        margin-bottom: 16px;
        z-index: 2000;
        overflow: visible;
        display: flex !important;
        align-items: center !important;
        height: 20px !important;
    }
    .casesArrayItem:hover::before {
        left: -20px !important;
        width: 20px !important;
        height: 20px !important;
    }
}
@media (max-width: 650px) {
    .cases-Array {
        bottom: 220px !important;
        left: 60px !important;
    }
    .cases-page-slider {
        left: 60px !important;
    }
}

@media (max-height: 650px) and (max-width: 1560px) {
    .cases-page .casesMain {
        display: none !important;
    }
    .cases-Array {
        flex-direction: column;
    }
    .cases-Array > div {
        flex-direction: row;
    }
    .casesArrayItem {
        margin-right: 50px;
    }
    .casesTextBlock {
        display: none;
    }
    .cases-Array {
        top: calc(27%) !important;
        bottom: auto !important;
        left: 60px !important;
    }
    .cases-page-slider {
        left: 60px !important;
    }
    .cases-Array > div {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media (max-height: 991px) and (max-width: 876px) {
    .cases-page .casesMain {
        display: none !important;
    }
    .cases-Array {
        flex-direction: column;
    }
    .cases-Array > div {
        flex-direction: row;
    }
    .casesArrayItem {
        margin-right: 50px;
    }
    .casesTextBlock {
        display: none;
    }
    .cases-Array {
        top: calc(25%) !important;
        bottom: auto !important;
        left: 60px !important;
    }
    .cases-page-slider {
        left: 60px !important;
    }
    .cases-Array > div {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
@media (max-height: 500px) and (max-width: 5000px) {
    .casesTextBlock {
        display: none;
    }
    .casesArray {
       display: none;
    }
    .casesMain {
        display: none;
    }
    .casesBtn, .pos33 {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
    .casesBtnPage {
        right: 6px !important;
        bottom: 60px !important;
        left: auto !important;
    }
}


