.fill2 {
    position: absolute;
    z-index: 5;
    display: block;
    width: 0;
    height: 100%;
    background-color: #e4b50b;
    transition: all 1s linear !important;

    -webkit-position: absolute;
    -webkit-z-index: 5;
    -webkit-display: block;
    -webkit-width: 0;
    -webkit-height: 100%;
    -webkit-background-color: #e4b50b;
    -webkit-transition: all 1s linear !important;
}

.video-object-special {
    height: 100vh;
}

.video-toner-special {
    height: 100vh;
}

.video-controls-special {
    justify-content: center;
    bottom: 126px;
}

.video-controls-special .video-center {
    justify-content: center;
}

.bar {
    width: 100%;
}