.hire-footer-right p a {
    color: #dae0f2;
    text-decoration: underline !important;
}

@media (min-width: 1920px) {
    .secondCircle{
        left: 60px;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 48px !important;
      }
    .hirePageVk {
       
    }
    .text-block {
        height: 50vh !important;
    }
    .heightClass {
        height: 77vh !important;
    }
    .heightClass2 {
        height: 63vh !important;
    }
    .fhd {
        display: block !important;
    }
    .thirdCircle{
        
    }
    .mobile__btns {
        display: none;
        -webkit-display: none;
    }
    .storyBlock h3 {
        font-size: 48px;
        line-height: 57px;
        min-width: 576px;
    }
    .storyBlock div {
        font-size: 22px;
        padding-left: 190px;
    }
    .casesArrow {
        margin: 0;
        padding: 0 !important;
    }
    .casesMain {

    }
    .casesMain p:nth-child(1) {
        font-size: 120px;
    }
    .gradientBtn {
        width: 144px;
        height: 144px;
    }
    .shape {
        width: 120px;
        height: 120px;
    }
    .gradientBtn p {

    }
    .firstCircle {

    }
    .clickedCases {
        top: 110px;
    }
    .hireBtn p {
        
    }
    .hireBLock__left__bottom {
        font-size: 48px;
    }
    .contact__tel p:nth-child(1), .contact__tel p:nth-child(1) a{
        font-size: 36px !important;
    }
    .contact:nth-child(1) {
        margin-right: 48px;
    }
    .hireBLock {

    }
    .gradientBtnContact {
        width: 144px !important;
        height: 144px !important;
        top: 100px !important;
    }
    .posStickBtn {
        
    }
    .gradientBtnContact p {
        top: 29% !important;
    }
    .casesTextBlock p {

    }
    .cases-page-slider {
        left: calc(20%) !important;
    }
    .current-page {
        padding-bottom: 70px !important;
        font-size: 18px !important;
    }
    .pos32 {
        right: 55px !important;
        bottom: 50px !important;
    }
    .pos3 {
        bottom: -110px;
        right: 75px;
    }
    .hireBtnPage {
        bottom: -120px !important;
    }
    .deb {
        
    }
}
@media (min-width: 1955px) {
    .cases-page-slider {
        left: calc(30%) !important;
    }
}
@media (min-width: 2555px) {
    #MainVideo_ {
        width: 128% !important;
    }
    .posStickBtn .gradientBtnContact {
        width: 110px !important;
        height: 110px !important;
        border-radius: 50%;
        position: relative; 
        background: linear-gradient(125deg, #7344f4 10%, #eec01b 50%, #7344f4 90%);
        background-size: 600% 600%;
        box-shadow: 0 0 0 0 #eec01b;
        animation: gradient 5s ease infinite;
    }
    .text-block div:nth-child(1) {
      
        font-weight: normal;
        text-transform: uppercase;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 48px !important;
      }
    .pos33 {
        bottom: 90px;
        right: 90px;
    }
    .hirePageVk {
        margin-right: 30% !important;
        width: 53% !important;
        height: 556px !important;
        margin-top: 15% !important;
    }
    .text-block {
        height: 45vh !important;
    }
    .heightClass {
        height: 52vh !important;
    }
    .heightClass2 {
        height: 45vh !important;
    }
    .heightClass3 {
        height: 48vh !important;
    }
    .heightClass3 div:nth-child(1) {
        padding-top: 8% !important;
    }
    .fhd {
        display: block !important;
    }
    .deb {
        width: 55% !important;
        margin-top: 13% !important;
    }
    .pos32 {
        right: 90px !important;
        bottom: 90px !important;
    }
    .gradientBtn {
        width: 144px;
        height: 144px;
    }
    .shape {
        width: 120px;
        height: 120px;
    }
    .hireBLock__left__bottom {
        font-size: 48px;
    }
    .contact__tel p:nth-child(1), .contact__tel p:nth-child(1) a {
        font-size: 36px !important;
    }
    .contact:nth-child(1) {
        margin-right: 48px;
    }
    .casesMain {
        margin-top: 30%;
    }
    .casesMain p:nth-child(1) {
        font-size: 120px;
    }
    .firstCircle {
        top: 70px;
        left: 50%;
        background-size: 50%;
        width: 120px;
        height: 120px;
    }
    .pos3 {
        bottom: -110px;
        right: 90px;
    }
    .hireBtnPage {
        bottom: -120px !important;
    }
    .clickedCases {
        top: 120px !important;
        left: 50% !important;
    }
    .mobile__btns {
        display: none;
        -webkit-display: none;
    }
    .showreel__mobile,
    .swiper__mobile {
        display: none;
        -webkit-display: none;
    }
    .showreel {
        display: block;
    }
    .main__logo {
        width: 100px;
        height: 100px;
    }
    .MainPageButton {
        font-size: 25px;
        width: 100px;
        height: 100px;
    }
    .MainPageButton:hover::before {
        width: 120px;
        height: 120px;
    }
    .main {
        padding: 80px;
    }
    .typing__text {
        font-size: 30px;
    }
    .main__bottom {
        margin-bottom: 60px;
    }
    .casesBtn {
        right: 80px;
        bottom: 80px;
    }
    .casesTextBlock {
        bottom: 80px;
        left: 60px;
        font-size: 25px;
    }
    .casesArrow {
        width: 30px;
        height: 30px;
    }
    .casesArray {
        bottom: 10%;
        font-size: 55px;
    }
    .casesArray div {
        margin-top: 28px;
        height: 42px;
        font-size: 36px;
        line-height: 46px;
    }
    .mcDonalds:hover::before {
       
    }
    .casesMain p:nth-child(1) {
        font-size: 120px;
    }
    .casesMain p:nth-child(2) {
        font-size: 35px;
    }
    .storyBlock {
        margin-top: 15%;
        margin-left: 29%;
    }
    .storyBlock h3 {
        font-size: 48px;
        margin-right: 100px;
        line-height: 57px;
    }
    .storyBlock div {
        font-size: 22px;
        padding-left: 200px;
    }
    .casesArrow {
        margin: 0;
        padding: 0 !important;
    }
    .hireBLock {
        width: 60%;
        margin-top: 20%;
    }
    .hireBLock__left__top,
    .hireBLock__left__middle {
        font-size: 22px;
        width: 500px;
    }
    .hireBLock__left__bottom {
        font-size: 45px;
    }
    .hireBLock__right {
        font-size: 22px;
    }
    .hireBLock__right__bottom {
        font-size: 25px;
    }
    .gradientBtnContact {
        width: 144px !important;
        height: 144px !important;
        top: 100px !important;
    }
    .posStickBtn {
        top: 100px !important;
    }
    .gradientBtnContact p {
        top: 27%;
    }
    .cases-page .pos32 {
        right: 55px !important;
        bottom: 50px !important;
    }
    .cases-page .universalBack {
        -webkit-transform: perspective(1000px) rotateY(5deg) rotateX(-2deg) scale3d(1, 1, 1) !important;
        transform: perspective(1000px) rotateY(5deg) rotateX(-2deg) scale3d(1, 1, 1) !important;
        top: -9vh !important;
        left: calc(-20vw) !important;
        width: calc(127vw) !important;
        height: calc(110vh) !important;
      }
}
@media (min-width: 1550px) {
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 38px !important;
      }
}
@media (max-width: 2560px) and (max-height: 1080px) {
    .deb, .hirePageVk, .storyBlock {
        margin-top: 11% !important;
    }
}
@media (max-width: 2560px) {
    .text-block div:nth-child(1) {
       
        font-weight: normal;
        text-transform: uppercase;
    }
}
@media (max-width: 2320px) {
    .text-block div:nth-child(1) {
       
        font-weight: normal;
        text-transform: uppercase;
    }
    .hireBLock {
        margin-left: 170px;
    }
}
@media (max-width: 1920px) {
    .text-block div:nth-child(1) {
     
        font-weight: normal;
        text-transform: uppercase;
    }
    .hireBLock {
        margin-left: 150px;
        margin-top: 100px;
    }
}
@media (max-width: 1660px) {
    .hireBLock {
        margin-left: 80px;
    }
    .hireBLock__left {
        margin-right: 85px;
    }
}
@media (max-width: 1550px) {
    .personal-page .info-block article p {
        font-size: 72px;
        font-weight: 450;
    }
    .personal-page .info-block {
        padding: 90px 120px;
    }
    .personal-page .contactBTNMY {
        top: 120px;
        right: 120px;
    }
    .personal-page .pos6 {
        top: 129px;
        right: 129px;
    }
    .personal-page .line-block div p {
        font-size: 25px;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .personal-page .line-block-contact-big:nth-child(1) {
        font-size: 38px !important;
    }
    .personal-page .contact-block {
        width: 100% !important;
        background-color: #071013;
        padding: 120px;
        position: relative;
    }
    .personal-page .text-block div:nth-child(1) {
     
        font-weight: normal;
        text-transform: uppercase;
    }
    .personal-page .text-block {
        padding: 100px 120px;
    }
    .header-title {
        padding: 60px 0 0 60px;
        font-size: 60px;
        color: #edf5fc;
        font-weight: normal;
    }
    .storyBlock {
        margin-top: 100px;
        margin-left: 413px;
    }
    .storyBlock h3 {
        z-index: 2000;
        font-size: 30px;
        font-weight: 300;
    }
    .casesArrPadding {
        margin-top: 50px !important;
    }
    .storyBlock a, .storyBlock p {
        font-size: 15px;
    }
    .hireBtn {
        width: 110px;
        height: 110px;
    }
    .pos1 {
        width: 90px;
        height: 90px;
    }
    .hireBLock {
        margin-left: 120px;
        margin-top: 35px;
    }
    .hireBLock__right__middle h3, .contact__tel, .hireBLock a {
        font-weight: 450 !important;
        color: #DAE0F2;
        font-size: 17px !important;
    }
    .hireBLock__left__top a, .hireBLock__right__top p, .hireBLock__left__middle {
        font-size: 15px;
    }
    .hireBLock__right__middle {
        margin-top: 60px;
    }
    .hireBLock__right__middle p {
        color: #dae0f2;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 24px;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 40px !important;
    }
    .hireBLock__right__bottom {
        margin-top: 60px;
    }
    .hireBLock__left {
        margin-right: 80px;
    }
    .hireBLock__right__top p {
        margin-right: 25px;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 30px !important;
    }
    .next-project .storyBtn {
        position: absolute;
        top: auto !important;
        bottom: 96px !important;
        right: 120px !important;
    }
    .pos5 {
        top: auto !important;
        bottom: 108px;
        right: 129px !important;
      }
}
@media (max-width: 1440px) {
    .clickedStory {
        left: 40px;
    }
    .fhd {
        display: block !important;
    }
    .mobile__btns {
        display: none;
        -webkit-display: none;
    }
    .showreel__mobile,
    .swiper__mobile {
        display: none;
        -webkit-display: none;
    }
    .showreel {
        display: block;
    }
    .storyBlock {
        margin-top: 100px;
        margin-left: 413px;
    }
    .hireBLock__right__middle h3, .contact__tel, .hireBLock a {
        font-weight: 450 !important;
        color: #DAE0F2;
        font-size: 13px !important;
    }
    .hireBLock__left__top a {
        color: #DAE0F2;
        text-transform: uppercase;
        font-weight: 300;
        margin-right: 30px;
    }
    .hireBLock__left__top a, .hireBLock__right__top p, .hireBLock__left__middle {
        font-size: 14px !important;
    }
    .hireBLock__left__middle {
        color: #dae0f2;
        text-transform: uppercase;
        font-weight: 450;
        opacity: 0.6;
        margin-top: 30px;
    }
    .hireBLock__left {
        justify-content: flex-start;
    }
    .hireBLock__left__bottom {
        font-size: 30px;
        font-weight: 400;
        margin-top: 15px;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 30px !important;
    }
    .hireBLock__right__bottom {
        margin-top: 30px;
    }
    .hireBLock__right__bottom {
        font-weight: normal;
        font-size: 14px;
    }
    .contact p:nth-child(2) {
        padding-bottom: 15px;
    }
}
@media (max-width: 1336px) {
    .personal-page .info-block article p {
        font-size: 72px;
        font-weight: 450;
    }
    .personal-page .info-block {
        padding: 90px 120px;
    }
    .personal-page .contactBTNMY {
        top: 120px;
        right: 120px;
    }
    .personal-page .pos6 {
        top: 129px;
        right: 129px;
    }
    .personal-page .line-block div p {
        font-size: 25px;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .personal-page .line-block-contact-big:nth-child(1) {
        font-size: 38px !important;
    }
    .personal-page .contact-block {
        width: 100% !important;
        background-color: #071013;
        padding: 120px;
        position: relative;
    }
    .personal-page .text-block div:nth-child(1) {
        
        font-weight: normal;
        text-transform: uppercase;
    }
    .personal-page .text-block {
        padding: 100px 120px;
    }
    .header-title {
        padding: 60px 0 0 60px;
        font-size: 60px;
        color: #edf5fc;
        font-weight: normal;
    }
    .storyBlock {
        margin-top: 100px;
        margin-left: 295px;
    }
    .typing__text {
        display: none;
    }
}
@media (max-width: 1200px) {
    .personal-page .pos5 {
        top: auto !important;
        bottom: 54px !important;
        right: 69px !important;
        width: 90px !important;
        height: 90px !important;
      }
    .personal-page .next-project .storyBtn {
        position: absolute;
        top: auto !important;
        bottom: 45px !important;
        right: 60px !important;
    }
    .personal-page .info-block p {
        font-size: 20px !important;
    }
    .personal-page .info-block article p {
        font-size: 65px;
        font-weight: 450;
    }
    .personal-page .info-block {
        padding: 45px 60px !important;
    }
    .personal-page .contactBTNMY {
        top: 60px;
        right: 60px;
        width: 110px !important;
        height: 110px !important;
    }
    .personal-page .pos6 {
        top: 69px;
        right: 69px;
        width: 90px !important;
        height: 90px !important;
    }
    .personal-page .line-block div p {
        font-size: 19px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .line-block {
        margin-bottom: 30px !important;
        display: flex;
    }
    .personal-page .line-block-contact-big:nth-child(1) {
        font-size: 31px !important;
    }
    .big-block {
        margin-top: 45px !important;
    }
    .personal-page .contact-block {
        width: 100% !important;
        background-color: #071013;
        padding: 60px !important;
        position: relative;
    }
    .mt100 {
        margin-bottom: 50px !important;
    }
    .main-contact {
        font-size: 31px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .PreviewImages img {
        width: 100%;
        height: 70vh !important;
        object-fit: cover;
        object-position: center;
    }
    .personal-page .text-block div:nth-child(1) {
       
        font-weight: normal;
        text-transform: uppercase;
    }
    .personal-page .text-block-p {
        width: auto !important;
        font-size: 18px !important;
    }
    .personal-page .text-block {
        padding: 50px 60px !important;
    }
    .video-text {
        font-size: 13px !important;
        text-transform: uppercase;
        color: #edf5fc !important;
    }
    .header-title {
        padding: 40px 60px 0 60px;
        font-size: 52px !important;
        color: #edf5fc;
        font-weight: normal;
    }
    .header-desc {
        padding: 0 60px 45px 60px;
        font-size: 18px !important;
        font-weight: lighter;
        color: #edf5fc;
    }
    .personal-left div:nth-child(1) h1, .link {
        font-weight: normal;
        color: #edf5fc;
        font-size: 15px !important;
        line-height: 23.04px;
    }
    .personal-left div:first-child {
        margin-right: 180px;
    }
    .storyBtn {
        width: 110px !important;
        height: 110px !important;
    }
    .pos2 {
        width: 90px !important;
        height: 90px !important;
    }
    .storyBlock {
        margin-left: 230px;
    }
    .storyBlock h3 {
        font-size: 34px;
    }
    .storyBlock > div {
        padding-left: 90px;
    }
    .casesArrPadding {
        margin-top: 15px !important;
    }
    .storyBlock p {
        margin: 10px 0 10px 0;
    }
    .clickedStory {
        left: 10px;
    }
}
@media (max-width: 1024px) {
    .personal-page .pos5 {
        top: auto !important;
        bottom: 31px !important;
        right: 39px !important;
        width: 90px !important;
        height: 90px !important;
      }
    .personal-page .next-project .storyBtn {
        position: absolute;
        top: auto !important;
        bottom: 22px !important;
        right: 30px !important;
    }
    .personal-page .info-block p {
        font-size: 15px !important;
    }
    .personal-page .info-block article p {
        font-size: 45px;
        font-weight: 450;
    }
    .personal-page .info-block {
        padding: 22px 30px !important;
    }
    .personal-page .contactBTNMY {
        top: 30px;
        right: 30px;
        width: 110px !important;
        height: 110px !important;
    }
    .personal-page .pos6 {
        top: 39px;
        right: 39px;
        width: 90px !important;
        height: 90px !important;
    }
    .personal-page .line-block div p {
        font-size: 16px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .line-block {
        margin-bottom: 30px !important;
        display: flex;
    }
    .personal-page .line-block-contact-big:nth-child(1) {
        font-size: 28px !important;
    }
    .big-block {
        margin-top: 45px !important;
    }
    .personal-page .contact-block {
        width: 100% !important;
        background-color: #071013;
        padding: 30px !important;
        position: relative;
    }
    .mt100 {
        margin-bottom: 50px !important;
    }
    .main-contact {
        font-size: 27px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .PreviewImages img {
        width: 100%;
        height: 50vh !important;
        object-fit: cover;
        object-position: center;
    }
    .personal-page .text-block div:nth-child(1) {
     
        font-weight: normal;
        text-transform: uppercase;
    }
    .personal-page .text-block-p {
        width: auto !important;
        font-size: 14px !important;
    }
    .hide-controls {
        padding-bottom: 7px !important;
        font-size: 14px !important;
    }
    .play-btn, .muted-btn {
        font-size: 15px !important;
        width: 60px !important;
        margin-right: 10px !important;
    }
    .personal-page .text-block {
        padding: 25px 30px !important;
    }
    .animated-logo-X {
        top: 26px !important;
    }
    .personal-page .prev-slider {
        background-position-y: bottom;
        left: 30px !important;
    }
    .personal-page .next-slider {
        right: 30px !important;
    }
    .video-text {
        font-size: 11px !important;
        text-transform: uppercase;
        color: #edf5fc !important;
    }
    .header-title {
        padding: 30px 0 0 30px !important;
        font-size: 40px !important;
        color: #edf5fc;
        font-weight: normal;
    }
    .video-toner, .video-object, .video-video, .video-toner-special, .video-object-special {
        height: 70vh !important;
    }
    .header-desc {
        padding: 0 0 40px 30px !important;
        font-size: 15px !important;
        font-weight: lighter;
        color: #edf5fc;
    }
    .personal-left div:nth-child(1) h1, .link {
        font-weight: normal;
        color: #edf5fc;
        font-size: 15px !important;
        line-height: 23.04px;
    }
    .personal-left div:first-child {
        margin-right: 120px !important;
    }
    .personal-header {
        display: flex;
        padding: 30px 30px 0 !important;
        justify-content: space-between;
    }

    .fhd {
        display: block !important;
    }
    .mobile__btns {
        display: none;
        -webkit-display: none;
    }
    .showreel__mobile,
    .showreel {
        display: block;
    }
    .casesLayout {
        clip-path: polygon(51% 0%, 100% 15%, 100% 100%, 0 100%, 0 30%);
    }
    .casesArray {
        bottom: 200px;
        right: 60px !important;
        left: auto !important;
    }
    .storyBlock {
        margin-top: 100px;
    }
    .hireBLock {
        height: 490px;
        margin-top: 18%;
        margin-left: 12%;
    }
    .gradientBtn {
        width: 110px;
        height: 110px;
    }
    .shape {
        width: 90px;
        height: 90px;
    }
    .hireBLock__left {
        margin-right: 55px;
    }
}
@media (max-width: 900px) {
    .storyBlock h3 {
        font-size: 25px;
    }
    .storyBlock > div {
        padding-left: 38px;
    }
    .storyBlock p {
        font-size: 14px;
    }
    .storyBlock a, .storyBlock p {
        margin: 0px 0 10px 0;
    }
    .hireBLock {
        flex-direction: column;
        height: 490px;
        margin-top: 6%;
        margin-left: 30%;
    }
    .hireBLock__right__bottom {
        justify-content: flex-start;
    }
    .hireBLock__right__bottom .contact:first-child {
        margin-right: 50px;
    }
    .clickedHire {
        right: 15px !important;
    }
    .hireBLock__left__bottom {
        font-size: 21px;
        font-weight: 400;
        margin-top: 15px;
    }
    .hireBLock__right {
        margin-top: 20px;
    }
    .hireBLock__right__middle {
        margin-top: 30px;
    }
}
@media (max-width: 768px) {
    .personal-page .pos5 {
        top: auto !important;
        bottom: 31px !important;
        right: 39px !important;
        width: 60px !important;
        height: 60px !important;
      }
    .personal-page .next-project .storyBtn {
        position: absolute;
        top: auto !important;
        bottom: 22px !important;
        right: 30px !important;
    }
    .personal-page .info-block p {
        font-size: 15px !important;
    }
    .personal-page .info-block article p {
        font-size: 45px;
        font-weight: 450;
    }
    .personal-page .info-block {
        padding: 22px 30px !important;
    }
    .personal-page .storyBtn {
        width: 80px !important;
        height: 80px !important;
        font-size: 11px !important;
    }
    .personal-page .contactBTNMY {
        top: 30px;
        right: 30px;
        width: 80px !important;
        height: 80px !important;
        font-size: 11px !important;
    }
    .personal-page .pos6 {
        top: 39px;
        right: 39px;
        width: 60px !important;
        height: 60px !important;
    }
    .personal-page .line-block div p {
        font-size: 16px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .line-block {
        margin-bottom: 30px !important;
        display: flex;
    }
    .personal-page .line-block-contact-big:nth-child(1) {
        font-size: 28px !important;
    }
    .big-block {
        margin-top: 45px !important;
    }
    .personal-page .contact-block {
        width: 100% !important;
        background-color: #071013;
        padding: 30px !important;
        position: relative;
    }
    .mt100 {
        margin-bottom: 50px !important;
    }
    .main-contact {
        font-size: 27px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .PreviewImages img {
        width: 100%;
        height: 50vh !important;
        object-fit: cover;
        object-position: center;
    }
    .personal-page .next-proj {
        padding-top: 100px !important;
        color: #dae0f279;
    }
    .video-controls-special {
        justify-content: center;
        bottom: 65px !important;
    }
    .personal-page .next-project {
        background-size: cover !important;
    }
    .personal-page .text-block div:nth-child(1) {
       
        font-weight: normal;
        text-transform: uppercase;
    }
    .personal-page .text-block-p {
        width: auto !important;
        font-size: 12px !important;
    }
    .hide-controls {
        padding-bottom: 7px !important;
        font-size: 14px !important;
    }
    .play-btn, .muted-btn {
        font-size: 15px !important;
        width: 60px !important;
        margin-right: 10px !important;
    }
    .personal-page .text-block {
        padding: 25px 30px !important;
    }
    .animated-logo-X {
        top: 26px !important;
    }
    .personal-page .prev-slider {
        background-position-y: bottom;
        left: 30px !important;
    }
    .personal-page .next-slider {
        right: 30px !important;
    }
    .video-text {
        font-size: 11px !important;
        text-transform: uppercase;
        color: #edf5fc !important;
        display: none !important;
    }
    .header-title {
        padding: 30px 0 0 30px !important;
        font-size: 30px !important;
        color: #edf5fc;
        font-weight: normal;
    }
    .video-toner, .video-object, .video-video, .video-toner-special, .video-object-special {
        height: 70vh !important;
    }
    .header-desc {
        padding: 0 0 40px 30px !important;
        font-size: 15px !important;
        font-weight: lighter;
        color: #edf5fc;
    }
    .personal-page .desc {
        margin-top: 5px !important;
    }
    .personal-left div:nth-child(1) h1, .link {
        font-weight: normal;
        color: #edf5fc;
        font-size: 15px !important;
        line-height: 23.04px;
    }
    .personal-left div:first-child {
        margin-right: 35px !important;
    }
    .personal-header {
        display: flex;
        padding: 30px 30px 0 !important;
        justify-content: space-between;
    }



    .fhd {
        display: block !important;
    }
    .mobile__btns {
        display: none;
        -webkit-display: none;
    }
    .showreel__mobile,
    .swiper__mobile {
        display: none;
        -webkit-display: none;
    }
    .showreel {
        display: block;
    }
    .casesArray {
        bottom: 60px;
        left: 44px !important;
        right: auto !important;
        font-size: 30px !important;
    }
    .casesMain {
        bottom: 660px;
    }
    .storyLayout {
        clip-path: polygon(18% 0, 100% 0, 100% 100%, 12% 89%);
    }
    .storyBlock h3 {
        font-size: 18px;
    }
    .storyBlock div {
        font-size: 12px;
    }
    .storyBlock p {
        font-size: 11px;
      }
    .hireBLock__left {
        font-size: 15px;
    }
    .hireBLock__left__middle {
      
    }
    .hireBLock {
        
    }
    .hireBtn {
        top: 20px;
        left: 20px;
    }
    .hireBLock__right__middle {
        
    }
    .hireBLock__right__bottom {
        
    }
    .hireBLock__right__bottom .contact:nth-child(1) {
        margin-right: 10px;
    }
    .hireLayout {
        clip-path: polygon(0 0, 85% 0, 91% 90%, 0 100%);
    }
    .casesTextBlock {
        width: 30% !important;
    }
    .casesArray div {
    }
    .pos1 {
        top: 27px;
        left: 27px;
    }
    .hireBLock__left__top a {
        font-size: 15px !important;
    }
    .hireBLock__right__top p,
    .hireBLock__right__top a {
        font-size: 15px !important;
    }
    .casesTextBlock p {
        font-size: 15px !important;
        display: none;
    }
    .casesArray div {
        margin-top: 20px !important;
    }
    .storyBlock {
        flex-direction: column !important;
        margin-left: 185px;
        margin-top: 55px;
    }
    .storyBlock > div {
        padding-left: 0;
        margin-top: 13px;
    }
    .casesArrow  {
        display: none !important;
    }
}
@media (max-width: 636px) {
    .full_video_modal_content_video div {
        height: 26vh !important;
    }
    .text-block-div .text-block-p:first-child {
        width: 100% !important;
    }
    .hireBLock {
        margin-left: 60px;
    }
    .hireBtn {
        left: auto !important;
        right: 105px;
    }
    .pos1 {
        left: auto !important;
        right: 117px;
    }
    .hireBLock__left__top a, .hireBLock__right__top p, .hireBLock__left__middle {
        font-size: 12px !important;
    }
    .hireBLock__left__bottom {
        font-size: 18px;
        font-weight: 400;
        margin-top: 15px;
    }
    .hireBLock__right__middle p {
        color: #dae0f2;
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 24px;
    }
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 23px !important;
    }
}
@media (max-width: 612px) {
    .storyBtn {
        bottom: 60px;
        top: auto !important;
    }
    .pos2 {
        bottom: 70px;
        top: auto !important;
    }
    .storyBlock {
        margin-top: 50px;
        margin-left: 155px;
    }
    .hireBtn, .pos1 {
        display: none;
    }
    .personal-left {
        justify-content: flex-start !important;
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .personal-page .hide-controls {
        display: none !important;
    }
    .personal-page .video-center {
        display: flex;
        width: 100% !important;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .personal-page .main-contact {
        font-size: 20px !important;
        color: #edf5fc;
        text-transform: uppercase;
    }
    .personal-page .line-block {
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
    }
}
@media (max-width: 480px) {
    .casesTextBlock .casesArrow {
        display: none;
    }
}
@media (max-height: 500px) and (max-width: 5000px) {
    .casesTextBlock {
        display: none;
    }
    .casesArray {
        bottom: 60px;
        left: 60px !important;
        right: auto !important;
    }
    .casesMain {
        bottom: 400px;
    }
}
@media (max-height: 500px) and (max-width: 5000px) {
    .casesTextBlock {
        display: none;
    }
    .casesArray {
       display: none;
    }
    .casesMain {
        display: none;
    }
    .casesBtn, .pos33 {
        left: 50%;
        right: auto !important;
        transform: translateX(-50%);
    }
    .casesBtnPage {
        right: 6px !important;
        bottom: 60px !important;
        left: auto !important;
    }
}

@media (max-width: 1367px) {
    .header-title p:first-child {
        position: relative;
        left: 0px !important;
    }
    .muted-btn {
        margin-right: 0 !important;
    }
    .fullscreen {
        margin-right: 10px;
    }
    .no_overflow {
        overflow-y: hidden !important;
    }
    
    .mainPageSelector {
        overflow: hidden !important;
    }
    .go_to_full_controls .play-mute div:nth-child(3) {
        order: 3 !important;
    }
    .go_to_full_controls .play-mute div:nth-child(2) {
        width: 70% !important;
        order: 2 !important;
        margin-top: 0 !important;
    }
    .go_to_full_controls .play-mute {
        flex-wrap: nowrap !important;
        margin-bottom: 25px !important;
    }
    .go_to_full > div {
        height: 100% !important;
    }
    .show_modal {
        height: 100vh !important;
    }
    .video-controls-full .video-center .play-mute div .fullscreen {
        width: 36px !important;
    }
    .video-controls-full .video-center .play-mute div button {
        width: auto !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
    .video-controls-full .bar, .video-controls-full .inputProgress {
        width: 100%;
    }
    .video-controls-full .video-center, .video-controls-full .video-center .play-mute {
        width: 100%;
        flex-wrap: wrap;
    }
    .video-controls-full .video-center .play-mute {
        justify-content: space-between;
    }
    .video-controls-full .video-center .play-mute div:nth-child(1) {
        order: 1;
    }
    .video-controls-full .video-center .play-mute div:nth-child(2) {
        order: 3;
        width: 100%;
        margin-top: 36px;
    }
    .video-controls-full .video-center .play-mute div:nth-child(3) {
        order: 2;
        display: flex;
        align-items: center;
    }
    .video-controls-full {
        width: 100%;
        padding: 0 25px !important;
        bottom: 48px;
    }
    .full_video_modal_content_video video {
        width: 100%;
        height: 25vh;
        object-fit: cover;
    }
    .full_video_modal_content_video {
        width: 100%;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .full_video_modal_logo .to-home {
        display: block;
    }
    .full_video_modal_logo {
        position: absolute;
        z-index: 8;
        width: 52px;
        height: 60px;
        top: 60px;
        right: 48px;
    }
    .full_video_modal_content {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .full_video_modal_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .full_video_modal {
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background: #000;
        transition: height 0.5s ease-in-out;
        z-index: 9999999;
    }
    .full_video_modal_x {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-image: url('../../images/X.svg');
        background-repeat: space;
        background-position: center;
    }
    .full_video_modal_noise {
        position: absolute;
        z-index: 6;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0.06;
        left: 0;
        background-image: url('../../images/bg-noise.gif');
        position: absolute;
        background-repeat: repeat;
        background-size: 10%;
    }
    * {
        cursor: none !important;
    }
    .animated-logo-X {
        filter: none !important;
     }
    .mainPageSelector {
        animation: unFocusFocusMobile 2s forwards !important;
    }
    .video__filter {
        filter: none !important;
    }
    .movable, .hoveredCursorClass {
        
    }
    .sliderWrapper, .sliderWrapper .slick-slider {
        height: 40vh !important;
        min-height: 158px !important;
    }
    .cpm_next_project_block_text p:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #DAE0F2;
    }
    .cpm_next_project_block_text p:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #DAE0F2;
    }
    .cpm_next_project_block_text {
        position: absolute;
        top: 50%;
        z-index: 3 !important;
        text-align: center;
        left: 0;
        width: 100%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        font-family: 'Futura PT' !important;
    }
    .text-block-special-bg {
        background-color: transparent !important;
    }
    .cpm_next_project_block_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #071013;
        opacity: 0.36;
        z-index: 2;
    }
    .cpm_next_project_block img {
        width: 100% !important;
        height: 22vh !important;
        min-height: 158px !important;
        object-fit: cover !important;
    }
    .cpm_next_project_block {
        position: relative;
    }
    .cpm_next_project {
        margin-bottom: 20px !important;
        display: block !important;
        text-align: center !important;
        font-family: 'Futura PT' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        line-height: 108% !important;
        letter-spacing: -0.02em !important;
        text-transform: uppercase !important;
        color: #EDF5FC !important;
        opacity: 0.48 !important;
    }
    .line-block div p:first-child {
        font-size: 18px !important;
    }
    .line-block div p {
        font-weight: 500 !important;
    }
    .line-block div {
        margin-bottom: 36px !important;
        margin-right: 0 !important;
    }
    .position {
        font-size: 24px !important;
        margin-top: 24px !important;
        font-weight: 500 !important;
    }
    .main-contact {
        text-align: center !important;
        font-size: 24px !important;
    }
    .main-contact p {
        margin-bottom: 18px !important;
    }
    .line-block {
        flex-wrap: wrap !important;
        justify-content: center !important;
        text-align: center !important;
        flex-direction: column !important;
    }
    .contact-block .contactBTNMY, .contact-block .pos6{
        display: none !important;
     }
    .contact-block {
        padding: 0px 25px !important;
        background-color: transparent !important;
        margin-bottom: 96px !important;
    }
    .cpm_button_position {
        position: relative;
    }
    .PreviewImages img {
        height: 39vh !important;
        min-height: 158px !important;
    }
    .desc p:nth-child(2) {
        font-weight: 400 !important;
        font-size: 18px !important;
        letter-spacing: -0.02em !important;
        color: #DAE0F2 !important;
        opacity: 0.72 !important;
    }
    .text-block div:nth-child(1) {
        margin-right: 0 !important;
    }
    .desc {
        margin-top: 36px !important;
    }
    .next-slider {
        right: 25px !important;
        background-image: url('../../images/elipse_r_b.png') !important;
    }
    .prev-slider {
        left: 25px !important;
        background-image: url('../../images/elipse_b.png') !important;
    }
    .prev-slider, .next-slider {
        background-color: transparent !important;
        animation: none !important;
        bottom: 50% !important;
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -o-transform: translateY(50%);
        transform: translateY(50%);
    }
    .personal-slider {
        height: 40vh !important;
        min-height: 158px !important;
    }
    .personal-slider-mobile {
        width: 100vw !important;
        height: 205px !important;
    }
    .personalSlider .slick-slide:hover {
        
    }
    .text-block-p:last-child {
        order: 1 !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        color: #DAE0F2 !important;
        margin-bottom: 24px !important;
    }
    .text-block-p:first-child {
        order: 2 !important;
        margin-right: 0 !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        letter-spacing: -0.02em !important;
        text-transform: uppercase !important;
        color: #EDF5FC !important;
    }
    .text-block-p:first-child span {
        order: 2 !important;
        margin-right: 0 !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        letter-spacing: -0.02em !important;
        text-transform: uppercase !important;
        color: #EDF5FC !important;
    }
    .text-block-div {
        flex-direction: column !important;
    }
    .text-block {
        padding: 70px 25px 48px 25px !important;
        background-size: auto !important;
        box-shadow: none !important;
    }
    .sliderWrapper {
        position: relative;
        overflow: hidden;
    }
    .paginationSliders {
        top: 18px !important;
        left: 25px !important;
    }
    .sl-logos_ {
        display: flex;
        position: absolute;
        left: 25px;
        bottom: 18px;
    }
    
    .btn_play_video {
        display: block;
        position: absolute;
        right: 25px;
        bottom: 18px;
        padding: 6px 18px !important;
        background-color: #EEBF1B;
        color: #071013;
        font-family: 'Futura PT';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        text-shadow: 0px 4px 12px rgba(7, 16, 19, 0.24);
    }
    .sl-itm > img {
        width: 100%;
        height: 40vh !important;
        min-height: 158px !important;
        object-fit: cover;
    }
    .sl-itm {
        display: block !important;
        position: relative;
        margin-bottom: -5px !important;
    }
    .cpm_ .header-title {
        padding: 0px 25px 18px 25px !important;
        font-weight: 500 !important;
        font-size: 36px !important;
        letter-spacing: -0.02em !important;
        color: #DAE0F2 !important;
    }
    .cpm_ .header-desc {
        padding: 0px 25px 0px 25px !important;
        margin-bottom: 60px !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        color: #DAE0F2 !important;
    }
    .story-footer-left-desk {
        display: none !important;
    }
    .story-footer-left-mobile {
        display: flex !important;
    }
    .violet_on_hover {
        width: max-content !important;
    }
    .marquee-img, .marquee-img-reverse {
        height: 130px !important;
        background-size: contain !important;
    }
    .marquee-div {
        display: flex;
        width: 600vw !important;
        animation: marquee 28s linear infinite !important;
    }
    .marquee-div-reverse {
        display: flex;
        width: 600vw !important;
        animation: marquee-reverse 28s linear infinite !important;
    }
    .story-bottom-marquee span,.story-bottom-marquee-reverse span {
        width: 300vw !important;
    }
    .story-mobile-ellipse {
        margin-top: 35px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        position: relative;
    }
    .backHref {
        font-weight: 500 !important;
        font-size: 18px !important;
        margin-bottom: 60px !important;
        position: absolute;
        top: 30%;
        z-index: 8;
        left: 0;
    }
    .story-mobile .backHref {
        position: relative !important;
    }
    .hmb02 {
        bottom: -112px !important;
        right: 25px !important;
        width: 96px !important;
        height: 96px !important;
    }
    .hmb02 p {
        display: block !important;
        margin: 0 auto !important;
    }
    .hmb03 {
        bottom: -150px !important;
    }
    .story-mobile-text3 {
        margin-top: 36px !important;
        font-size: 18px !important;
    }
    .story-mobile-text1 {
        font-size: 36px !important;
        font-family: 'Futura PT' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 36px !important;
        letter-spacing: -0.02em !important;
        margin-bottom: 60px !important;
    }
    .violetMobileMarg {
        width: 96px !important;
        height: 96px !important;
        position: absolute !important;
        left: auto !important;
        bottom: auto !important;
        right: 25px !important;
        top: 112px !important;
        margin-bottom: 0 !important;
        display: block !important;
        margin-right: 0 !important;
    }
    .violetMobileMarg p {
        margin-right: 0 !important;
    }
    .gradientBtn p {
        font-size: 12px !important;
    }
    .violetBtn p {
        padding-top: 0 !important;
        display: block !important;
        position: relative !important;
        top: 50% !important;
        font-size: 12px !important;
        -webkit-transform: translateY(-50%) !important;
        -moz-transform: translateY(-50%) !important;
        -o-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }
    .violetBtn {
        margin: 0 auto !important;
        margin-top: 96px !important;
        margin-bottom: 96px !important;
        width: 96px !important;
        height: 96px !important;
        font-size: 12px !important;
    }
    .hire-footer-right div {
        margin-bottom: 36px !important;
    }
    .hire-footer-right {
        margin-top: 72px !important;
        position: relative !important;
        padding: 0 25px !important;
        font-family: 'Futura PT' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        text-transform: uppercase !important;
        color: #DAE0F2 !important;
    }
    .hire-footer-right p {
        opacity: 0.6 !important;
    }
    .story-mobile-text {
        position: relative;
    }
    .story-mobile-text div {
        padding: 0 !important;
    }
    .story-mobile-text2 {
        font-size: 18px !important;
        color: #DAE0F2 !important;
    }
    .hire-social {
        padding: 0 25px !important;
        margin-bottom: 96px !important;
    }
    .hire-social a {
        margin-right: 36px !important;
    }
    .story-full-text-phone, .story-full-text-phone a {
        font-family: 'Futura PT' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 24px !important;
        line-height: 100% !important;
        letter-spacing: -0.02em !important;
        color: #DAE0F2 !important;
        margin-top: 12px !important;
        margin-bottom: 60px !important;
    }
    .story-full-text-mail, .story-full-text-mail a {
        color: #DAE0F2 !important;
        font-size: 36px !important;
        font-family: 'Futura PT' !important;
        font-style: normal !important;
        font-weight: 500 !important;
    }
    .story-contact p:nth-child(2) {
        color: #eebf1b !important;
        opacity: 0.6 !important;
        margin-bottom: 24px !important;
    }
    .story-contact {
        padding: 0 25px !important;
    }
    .norm {
        font-size: 36px !important;
        padding: 25px 25px 70px 25px !important;
    }
    .story-p-text {
        padding: 0 25px 25px 25px !important;
        color: #DAE0F2 !important;
        opacity: 0.6 !important;
    }
    .violetMobileMargStoryFull {
        bottom: -135px !important;
        top: auto !important;
        right: 35px;
        left: auto !important
    }
    .story-full-text {
        padding: 0 25px 70px 25px !important;
        text-transform: none !important;
        font-size: 36px !important;
    }
    .story-footer-right p:nth-child(2) {
        order: 1;
    }
    .story-footer-right div {
        order: 2;
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .story-footer-left {
        order: 2;
        align-items: center;;
    }
    .story-footer-middle {
        order: 1;
        width: 55px !important;
        height: 55px !important;
        margin-bottom: 60px !important;
    }
    .story-footer-right {
        order: 3;
        font-size: 15px !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .story-footer-left a {
        width: max-content;
        font-size: 18px !important;
        font-weight: 500 !important;
        margin-bottom: 36px !important;
    }
    .story-footer-left a.active {
        border-bottom: 2px solid #EEBF1B;
    }
    .story-footer {
        flex-direction: column !important;
    }
    .go_to_full {
        top: 0;
        transform: rotate(90deg);
        left: 0;
    }
    .go_to_full_controls {
        top: 0;
        transform: rotate(90deg);
        left: 0;
    }
    .mb02 {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        bottom: auto !important;
        right: auto !important;
        margin: 0 auto;
        margin-bottom: 70px !important;
        width: 96px !important;
        height: 96px !important;
        font-size: 12px !important;
    }
    .mar70 {
        margin-top: 70px;
    }
    .slide-content {
        position: absolute;
        bottom: 50px;
        left: 25px;
        z-index: 99;
    }
    .sftf {
        font-size: 24px !important;
        letter-spacing: -0.02em !important;
        text-transform: uppercase !important;
        font-weight: 500 !important;
    }
    .stftf2 {
        margin-top: 70px !important;
        font-weight: 500 !important;
        padding: 0 25px !important;
        font-size: 24px !important;
        line-height: 108% !important;
        letter-spacing: -0.02em !important;
        text-transform: uppercase !important;
        margin-bottom: 48px !important;
    }
    .spt2 {
        font-size: 24px !important;
        text-transform: none !important;
    }
    .main {
        background-image: url('../../images/X.svg');
        background-repeat: space;
        background-position: center center;
    }
    .cases-mobile-bg {
        background-image: url('../../images/X.svg');
        background-repeat: space;
        background-position: center center;
        width: 100vw;
        height: 100vh;
        position: relative;
    }
    .cases-mobile-bg-overlay-sh {
        opacity: 0.06;
        background-image: url('../../images/bg-noise.gif');
        background-repeat: repeat;
        background-size: 10%;
        width: 100vw;
        height: 100vh;
    }
    .cases-mobile-bg-overlay {
        background: rgb(255, 255, 255, 0);
        background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.8432948179271709) 100%
        );
        width: 100vw;
        height: 100vh;
        position: relative;
    }
    #typing__text_mobile {
        display: block;
        position: absolute;
        top: 60px !important;
        left: 50px !important;
    }
    .typing__text {
        display: inline;
    }
    .mobile {
        display: block !important;
    }
    .mobile__btns button {
        z-index: 20002;
        width: 60px;
        height: 30px;
        color: #fff;
        font-size: 18px;
        border: none;
        background: transparent;
        text-transform: uppercase;
    }
    .mobile__btns button:nth-child(2) {
        margin-top: -100px;
    }
    .mobile__btns {
        z-index: 20002;
        display: flex !important;
        -webkit-display: flex !important;
        flex-direction: column;
        position: absolute;
        right: 50px;
        bottom: 140px;
    }
    .mobile__btns a {
        margin-bottom: 60px;
        padding: 5px 12px !important;
        transition: all 0.3s ease-in-out;
    }
    .mobile__btns a:hover {
        background: rgba(237, 245, 252, 0.24);
    }
    .mobile__btns a:nth-child(2) {
        margin-bottom: 170px;
    }
    .mobile__btns a:last-child {
        margin-bottom: 0px;
    }
    .showreel {
        display: block;
        -webkit-display: none;
    }
    .showreel__mobile {
        display: none;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100vw;
        height: 100%;
        position: absolute !important;
        content: '';
        z-index: -9;
        background-image: url('../MainPage/video/videoPower.gif');
    }
    .swiper__mobile {
        display: flex;
    }
    .cube,
    .secondCircle,
    .thirdCircle,
    .firstCircle,
    #cases_movedHoveredButton,
    #story_movedHoveredButton,
    #hireus_movedHoveredButton {
        display: none;
    }
    .casesLayout {
        overflow: hidden;
    }
    .slick-slide {
        z-index: 2000;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
    }
    #cases-slider-wraper .slick-slide {
        background-color: #071013 !important;
    }
    .swiper-item {
        background-image: url('../../images/Showreel.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100vw;
        height: 100vh;
        clip-path: polygon(0 100%, 3% 1.6%, 100% 0, 95% 98%);
    }
    .swiper-item div:nth-child(1) {
        z-index: 2000;
        color: #dae0f2;
    }
    .swiper-item div:nth-child(2) span {
        color: #fff;
    }
    .swiper-item div:nth-child(2) {
        color: #dae0f2;
    }
    .swiper-item article p {
        font-size: 36px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.02em;
        color: #DAE0F2;
    }
    .swiper-item p {
        font-size: 18px;
        margin-bottom: 115px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #DAE0F2;
    }
    .movable {
        
    }
    #overlay1,
    #overlay2,
    #overlay3 {
        display: none;
    }
    .main {
        height: 100vh;
        padding: 20px;
    }
    .main__logo {
        top: 60px !important;
        right: 50px !important;
    }
    .main__bottom div {
        margin: 0px !important;
    }
    .main__bottom__video {    
        content: '';
        width: 340px;
        transform: rotate(-90deg);
        bottom: 248px;
        position: absolute;
        left: -120px;
    }
    .main__bottom {
        position: absolute;
        width: 332px;
        content: '';
        height: 35px;
        left: calc(-29%);
        bottom: 20px;
        margin-bottom: 166px;
    }
    .progress-done {
        margin: 0;
    }
    .MainPageButton {
        border-radius: 0px;
        height: 36px;
        width: 90px;
    }
    .storyLayout,
    .hireLayout,
    .casesLayout {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 0) !important;
    }
    .clickedStory,
    .clickedCases,
    .clickedHire,
    .casesTextBlock,
    .casesArray {
        display: none;
    }
    .casesBtn {
        bottom: 20px;
        right: 61px;
    }
    .storyBtn {
        top: 20px;
        right: 20px;
    }
    .storyBlock {
        margin-top: 30%;
        width: 85%;
    }
    .hireBLock {
        flex-direction: column;
        margin: 35% auto 0;
        align-items: center;
    }
    .hireBLock__left,
    .hireBLock__right {
        width: 335px;
    }
    .hireBLock__left__top {
        margin: 15px 0;
    }
    .hireBLock__right__top {
        align-items: flex-start;
        flex-direction: column;
    }
    .hireBLock__left__middle,
    .hireBLock__right__top {
        font-size: 14px;
    }
    .hireBLock__left__bottom {
        font-size: 30px;
    }
    .hireBLock__right__middle,
    .hireBLock__right__bottom {
        margin-top: 15px;
    }
    .hireBLock__right__middle p,
    .hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
        font-size: 15px;
    }
    .main__bottom {
        position: absolute;
        left: 0;width: 100%;
        bottom: 30px;
        margin-bottom: 30px !important;
        height: 400px;
    }
    .logos_icon {
        width: 36px !important;
        height: 36px !important;
        margin-right: 12px;
        object-fit: contain;
    }
    .fhd{
        display: none !important;
    }
}
@media screen and (orientation:landscape) and (max-height: 1024px) and (max-width: 1367px) { 
    .full_video_modal_wrapper .backHref{
        top: 60px;
    }
    .play-mute {
        width: 100% !important;
    }
    .full_video_modal_content_video div {
        height: 100vh !important;
    }
    .btm0 {
        left: auto !important;
    }
    .cases-full-array div {
        
    }
    .mb02 {
        transform: none !important;
    }
    .story-mobile-ellipse {
        margin-top: 35px !important;
        margin-left: auto !important;
        position: relative;
        margin-right: auto;
    }
    .violetMobileMargStoryFull {
        right: 45px !important;
        transform: translateX(23%) !important;
    }
    .hmb02 {
        left: auto !important;
    }
    .mobile__btns a:nth-child(2) {
        margin-bottom: 60px !important;
    }
    .story-mobile-ellipse .violetMobileMarg {
        right: 25px !important;
    }
}
@media screen and (orientation:landscape) and (max-height: 710px) and (max-width: 1367px) { 
    .mobile__btns a:nth-child(2) {
        margin-bottom: 60px !important;
    }
    .main__bottom__video {
        content: '';
        width: 239px !important;
        transform: rotate(-90deg) !important;
        bottom: 209px !important;
        position: absolute !important;
        left: -60px !important;
    }
}
@media screen and (orientation:landscape) and (max-height: 645px) and (max-width: 1367px) { 
    .cases-full-array div {
        height: 158px !important;
    }
    .mobile__btns a:nth-child(2) {
        margin-bottom: 60px !important;
    }
    .main__bottom__video {
        transform: rotate(0deg) !important;
        bottom: 0 !important;
        position: absolute !important;
        left: 47px !important;
    }
    .mobile__btns {
        bottom: 50px !important;
    }
}
@media screen and (orientation:landscape) and (max-height: 476px) and (max-width: 1367px) { 
    .btm0 {
        left: auto !important;
    }
    .swiper-item p {
        margin-bottom: 40px !important;
    }
    .mobile__btns a {
        margin-bottom: 20px !important;
    }
    .mobile__btns a:nth-child(2) {
        margin-bottom: 20px !important;
    }
    .mobile__btns a:last-child {
        margin-bottom: 0px !important;
    }
}
@media screen and (orientation:landscape) and (max-width: 1367px) { 
    .go_to_full {
        transform: none !important;
        left: auto !important;
        top: auto !important;
    }
    .go_to_full_controls {
        transform: none !important;
        left: auto !important;
        top: auto !important;
        bottom: 0 !important;
    }
    .video-controls-full .video-center, .video-controls-full .video-center .play-mute {
        width: 98% !important;
    }
    .fullscreen {
        display: none;
    }
}
@media (min-width: 700px) and (max-width: 1367px) {
    .btm0 {
        right: 85px !important;
    }
    .caseBtnSlider {
        left: 60px;
    }
}
@media (max-width: 375px) {
    .main {
        padding: 30px;
    }
    .secondCircle,
    .thirdCircle,
    .firstCircle {
        left: calc(72%);
    }
    .main__logo {
        top: 30px;
        right: 30px;
    }
    .mobile {
        display: block !important;
    }
    .posMob1{
        bottom: 473px !important;
    }
    .posMob4{
        right: calc(39.5%) !important;
    }
    .caseLinkIMP{
bottom: 50px;   
    }
}
@media (max-width: 320px) {
    .secondCircle,
    .thirdCircle,
    .firstCircle {
        left: calc(69%);
    }
    .storyBlock {
        margin-top: 40%;
        flex-direction: column;
    }
    .mobile {
        display: block !important;
    }
}
@media (max-height: 1400px) {
    .firstCircle {
        top: 8% !important;
      }
}
@media (max-height: 980px) {
    .firstCircle {
        top: 6% !important;
      }
}
@media (max-height: 680px) {
    .firstCircle {
        top: 4% !important;
      }
}
.mobile__btns {
    display: none;
    -webkit-display: none;
}
.hireBLock__left__top a,
.hireBLock__right__top p,
.hireBLock__left__middle {
    font-size: 18px;
}
.hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
    font-size: 48px !important;
}
.fhd {
    display: block;
}

.mobile {
    display: none;
}
.mobile {
    width: 100%;
    height: 100%;
}

@media screen and (orientation:landscape) and (max-width: 1367px) { 
    .sliderWrapper, .sliderWrapper .slick-slider {
        height: 58vh !important;
        min-height: 158px !important;
    }
    .sl-itm > img {
        width: 100%;
        height: 58vh !important;
        min-height: 158px !important;
        object-fit: cover;
    }
    .personal-slider {
        height: 58vh !important;
        min-height: 158px !important;
    }
    .PreviewImages img {
        height: 75vh !important;
        min-height: 158px !important;
    }
    .header-title p:first-child {
        position: relative;
        left: 0px !important;
    }
}

@media screen and (orientation:landscape) and (max-width: 900px) { 
    .sliderWrapper, .sliderWrapper .slick-slider {
        height: 75vh !important;
        min-height: 158px !important;
    }
    .sl-itm > img {
        width: 100%;
        height: 75vh !important;
        min-height: 158px !important;
        object-fit: cover;
    }
    .personal-slider {
        height: 75vh !important;
        min-height: 158px !important;
    }
    .PreviewImages img {
        height: 90vh !important;
        min-height: 158px !important;
    }
    .header-title p:first-child {
        position: relative;
        left: 0px !important;
    }
}

@media (pointer:none), (pointer:coarse) {
    .movable, .hoveredCursorClass {
        display: none !important;
    }
}


