@media (min-width: 2560px) {
    .story-ellipse {
        top: 55% !important;
    }
}
@media (max-width: 2560px) {
    .story-ellipse {
        top: 55% !important;
    }
}
@media (max-width: 1920px) {
    .story-ellipse {
        top: 55% !important;
    }
}
@media (max-width: 1785px) {
    .story-ellipse {
        width: 550px !important;
        height: 550px !important;
        top: 55% !important;
    }
}
@media (max-width: 1500px) {
    .story-ellipse {
        width: 550px !important;
        height: 550px !important;
        top: 55% !important;
        right: 60px;
    }
    .story-main-content {
        padding: 190px 0 0 60px;
    }
    .story-main-content p:nth-child(1) {
        font-size: 28px;
    }
    .story-main-content p:nth-child(2), .story-main-content p:nth-child(3) {
        font-size: 20px;
    }
    .content-2-mb {
        padding: 85px 0 0 60px !important;
        margin-bottom: 0 !important;
    }
    .story-main-content p:nth-child(1) {
        font-size: 25px;
    }
    .marques {
        width: 100vw;
        margin-top: 60px !important;
        margin-bottom: 35px;
    }
    .story-bottom-marquee {
        margin-bottom: 0px;
    }
    .marquee-img, .marquee-img-reverse {
        height: 200px;
    }
    .story-footer {
        padding: 30px 60px;
    }
    .story-footer-left a {
        color: #fff;
        margin-right: 30px;
    }
    .story-footer-right {
        font-size: 15px;
    }
    .story-footer-right div p:nth-child(2) {
        margin-right: 30px;
    }
}
@media (max-width: 1100px) {
    .header-left div:nth-child(1) h1:nth-child(1) {
        font-size: 30px !important;
    }
    .header-left div:nth-child(1) h1:nth-child(2) {
        font-size: 30px !important;
    }
    .header-left div:nth-child(1) {
        margin-right: 75px !important;
    }
    .story-main-content {
        padding: 150px 0 0 60px !important;
    }
    .story-main-content p:nth-child(1) {
        font-size: 20px;
        margin-bottom: 40px;
    }
    .story-main-content p:nth-child(2), .story-main-content p:nth-child(3) {
        font-size: 15px;
    }
    .story-main-content p:nth-child(2) {
        margin-bottom: 15px;
    }
    .margin__special {
        margin-bottom: 50px;
    }
    .story-ellipse {
        width: 350px !important;
        height: 350px !important;
        top: 368px !important;
        right: 60px;
    }
    .content-2 {
        padding: 50px 0 0 60px !important;
    }
    .story-main-content p:nth-child(1) {
        font-size: 17px;
        margin-bottom: 40px;
    }
    .marques {
        width: 100vw;
        margin-top: 30px !important;
        margin-bottom: 30px;
    }
    .story-footer-left a {
        color: #fff;
        margin-right: 23px;
        font-size: 14px;
    }
    .story-footer-right {
        font-size: 11px;
    }
    .marquee-img, .marquee-img-reverse {
        height: 120px !important;
    }
}
@media (max-width: 800px) {
    .header-left div:nth-child(2) a {
        margin-right: 25px;
        font-size: 13px;
    }
    .header-left div:nth-child(1) {
        margin-right: 25px !important;
    }
    .header-left div:nth-child(1) h1:nth-child(1) {
        font-size: 25px !important;
    }
    .header-left div:nth-child(1) h1:nth-child(2) {
        font-size: 25px !important;
    }
    .header-left div:nth-child(1) h1:nth-child(1) {
        padding-right: 10px;
    }
    .story-main-content {
        padding: 120px 0 0 60px !important;
    }
    .story-main-content p:nth-child(1) {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .story-main-content p:nth-child(2), .story-main-content p:nth-child(3) {
        font-size: 13px;
    }
    .story-main-content p:nth-child(2) {
        margin-bottom: 5px;
    }
    .story-ellipse {
        width: 250px !important;
        height: 250px !important;
        top: 305px !important;
        right: 60px;
    }
    .content-2 {
        padding: 25px 0 0 60px !important;
    }
    .story-footer-left, .story-footer-right {
        flex-direction: row;
    }
    .story-footer-left a {
        margin-right: 25px !important;
        padding-bottom: 0px;
    }
    .story-footer-left a:last-child {
        margin-right: 0px !important;
        padding-bottom: 0px;
    }
    .story-footer {
        justify-content: flex-start;
    }
    .story-footer-left, .story-footer-middle, .story-footer-right {
        margin-bottom: 20px;
    }
    .story-footer-right div {
        margin-right: 20px;
    }
    .marquee-img, .marquee-img-reverse {
        height: 95px !important;
    }
}
@media (max-width: 608px) {
    .story-ellipse {
        width: 250px !important;
        height: 250px !important;
        top: 0 !important;
        left: 0 !important;
        right: auto !important;
        position: relative !important;
        margin: 0 auto;
    }
    .story-main-content {
        display: none !important;
    }
    .story-main-content-mobile {
        display: block !important;
    }
    .story-main-content-mobile {
        padding: 120px 60px 0 60px !important;
    }
    .story-main-content p:nth-child(1), .story-main-content p:nth-child(2), .margin__special {
        width: 100%;
    }
    .story-footer-left a {
        color: #fff;
        margin-right: 17px;
        font-size: 12px;
    }
    .story-footer-right {
        font-size: 10px;
    }
    .marques {
        margin-bottom: 5px !important;
    }
    .header-left {
        flex-direction: column;
    }
    .header-left div:nth-child(2) {
        margin-top: 10px;
    }
    .story-main-content-mobile {
        padding: 140px 60px 0 60px !important;
    }
}
@media (max-width: 608px) {
    .story-footer-left, .story-footer-right {
        flex-direction: column;
    }
    .story-footer-left a {
        margin-right: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 20px !important;
        min-height: auto !important;
    }
    .story-footer-right {
        margin-bottom: 0px !important;
    }
}