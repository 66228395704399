.cases-page-slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 140px;
    bottom: 60px;
    left: calc(30%);
    z-index: 20001;
    position: absolute;
}
.current-page {
    display: flex;
    color: #dae0f28c;
    padding-bottom: 20px;
    padding-left: 10px;
}
.current-page p {
    color: #fff;
}
.slider-buttons {
    display: flex;
    justify-content: space-between;
}
.slider-back,
.slider-next {
    padding: 0;
    margin: 0;
    content: '';
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.13);
    background-repeat: no-repeat;
    background-size: 90%;
    transition: 0.3s ease-in all;
}
.slider-back:hover {
    transition: 0.3s ease-in all;
    background-color: #eebf1b;
    background-size: 110%;
    background-position-x: -4px;
    animation: showSliderBtn 0.2s ease-in;
    background-position-y: -2px;
    background-image: url('../images/Cursorblackleft.svg');
}
.slider-next:hover {
    animation: showSliderBtn 0.2s ease-in;
    transition: 0.3s ease-in all;
    background-color: #eebf1b;
    background-size: 110%;
    background-position-x: 0px;
    background-position-y: 0px;
    background-image: url('../images/Cursorblack.svg');
}
.slider-back {
    background-position-x: -4px;
    background-position-y: -2px;
    background-image: url('../images/Ellipse\ 33left.svg');
}
.slider-next {
    background-position-x: 10px;
    background-position-y: -2px;
    background-image: url('../images/Ellipse\ 33right.svg');
}
@keyframes showSliderBtn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.casesSliderActive {
    box-shadow: 0 0 0 0px rgba(250, 253, 62, 0) ;
    animation: slideBtnYellow 1s ease-in-out;
}
.casesSliderActive:hover {
    box-shadow: 0 0 0 0px rgba(250, 253, 62, 0) ;
    animation: slideBtnYellow 1s ease-in-out ;
}
@keyframes slideBtnYellow {
    0% {
        box-shadow: 0 0 0 0px rgba(238, 192, 27, 0.4);
    }
    50% {
        box-shadow: 0 0 0 30px rgba(238, 192, 27, 0);
    }
    100% {
        box-shadow: 0 0 0 0px rgba(238, 192, 27, 0);
    }
}