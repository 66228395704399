.cursorWrapper {
    overflow: hidden;
    height: 100vh;
    width: 100vh;
    position: fixed;
    z-index: 999999999 !important;
    pointer-events: none;
    left: 0;
    top: 0;
}
.movable {
    width: 40px;
    height: 40px;
    filter: none; 
    -webkit-filter: blur(0px); 
    -moz-filter: blur(0px); 
    -ms-filter: blur(0px);
    -webkit-backface-visibility: hidden; 
    background-repeat: no-repeat;
    background-image: url('../../images/Cursor.svg');
    background-position: center;
    background-size: 100%;
    position: absolute !important;
    margin: auto !important;
    z-index: 999999999 !important;
    opacity: 0.9;
    -webkit-transition: width 0.5s ease, height 0.5s ease !important; 
    -moz-transition: width 0.5s ease, height 0.5s ease !important; 
    -o-transition: width 0.5s ease, height 0.5s ease !important; 
    transition: width 0.5s ease, height 0.5s ease !important; 
    -webkit-transform: scale(1) translate(-40%, -40%) translateZ(0);
    -moz-transform: scale(1) translate(-40%, -40%) translateZ(0);
    -o-transform: scale(1) translate(-40%, -40%) translateZ(0);
    transform: scale(1) translate(-40%, -40%) translateZ(0);
    transform-origin: center;
    pointer-events: none;
    overflow-y: hidden !important;
    /*animation: backCursor 0.3s ease-in;*/
}
@keyframes backCursor {
    from {
        -webkit-backface-visibility: hidden; 
        filter: none; 
        -webkit-filter: blur(0px); 
        -moz-filter: blur(0px); 
        -ms-filter: blur(0px);
        background-image: url('../../images/Cursor.svg');
        /*-webkit-transform: scale(4) translateZ(0);
        -moz-transform: scale(4) translateZ(0);
        -o-transform: scale(4) translateZ(0);
        transform: scale(4) translateZ(0);*/
        width: 160px;
        height: 160px;
    }
    to {
        -webkit-backface-visibility: hidden; 
        filter: none; 
        -webkit-filter: blur(0px); 
        -moz-filter: blur(0px); 
        -ms-filter: blur(0px);
        background-image: url('../../images/Cursor.svg');
        /*-webkit-transform: scale(2.5) translate(-20%, -10%) translateZ(0);
        -moz-transform: scale(2.5) translate(-20%, -10%) translateZ(0);
        -o-transform: scale(2.5) translate(-20%, -10%) translateZ(0);
        transform: scale(2.5) translate(-20%, -10%) translateZ(0);*/
        width: 100px;
        height: 100px;
    }
}
.hoveredCursorClass {
    -webkit-backface-visibility: hidden; 
    filter: none; 
    -webkit-filter: blur(0px); 
    -moz-filter: blur(0px); 
    -ms-filter: blur(0px);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
    pointer-events: none;
    background-image: url('../../images/CursorHovered.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    -webkit-transform: translate(-47%, -48%) translateZ(0) !important;
    -moz-transform: translate(-47%, -48%) translateZ(0) !important;
    -o-transform: translate(-47%, -48%) translateZ(0) !important;
    transform: translate(-47%, -48%) translateZ(0) !important;
    /*-webkit-transform: scale(4) translate(-10%, -10%) translateZ(0);
    -moz-transform: scale(4) translate(-10%, -10%) translateZ(0);
    -o-transform: scale(4) translate(-10%, -10%) translateZ(0);
    transform: scale(4) translate(-10%, -10%) translateZ(0);*/
    width: 160px !important;
    height: 160px !important;
    /*animation: hoveredCurs 0.5s ease forwards;*/
}
@keyframes hoveredCurs {
    from {
        -webkit-backface-visibility: hidden; 
        filter: none; 
        -webkit-filter: blur(0px); 
        -moz-filter: blur(0px); 
        -ms-filter: blur(0px);
        filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
        background-image: url('../../images/CursorHovered.svg');
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        width: 100px;
        height: 100px;
    }
    to {
        -webkit-backface-visibility: hidden; 
        filter: none; 
        -webkit-filter: blur(0px); 
        -moz-filter: blur(0px); 
        -ms-filter: blur(0px);
        filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
        background-image: url('../../images/CursorHovered.svg');
        -webkit-transform: translate(-47%, -48%) translateZ(0);
        -moz-transform: translate(-47%, -48%) translateZ(0);
        -o-transform: translate(-47%, -48%) translateZ(0);
        transform: translate(-47%, -48%) translateZ(0);
        width: 160px;
        height: 160px;
    }
}

/* @keyframes toStandartCursor {
    from {
        transform: scale(2.5);
    }
    to {
        transform: scale(1);
    }
}
.standartCursor {
    transition: transform 0.5s ease !important;
    transform: scale(2.5) translate(-40%, -40%);
    background-image: none !important;
    animation: toStandartCursor 0.3s linear forwards;
} */
