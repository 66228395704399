.story-mobile {
    background: #000;
    width: 100vw;
    background-image: url('../../images/X.svg');
    background-repeat: space;
    background-position: center;
    position: relative;
}.story-mobile .casesBtn{
    bottom: 380px;
}
.mobile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 25px;
    text-transform: uppercase;
    color: #dae0f2;
    align-items: center;
}
.link {
    color: #dae0f2;
    text-align: center;
    font-size: 18px !important;
}
.to-home {
    content: '';
    width: 70px;
    height: 70px;
    background-image: url('../../images/Logo.svg');
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
}
.story-mobile-text {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #dae0f2 !important;
}
.story-mobile-text1 {
    font-size: 30px;
    font-weight: lighter;
}
.story-mobile-text div {
    padding: 60px 0 36px 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
.arrow-to-left {
    padding: 0 !important;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('../../images/▼arrowTop.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transform: rotate(-90deg);
}
.story-mobile-text2,
.story-mobile-text3 {
    text-transform: uppercase;
    max-width: 240px;
}
.violetBtn {
    background: #7344f4;
    width: 100px;
    height: 100px;
    bottom: 30px;
    margin-left: calc(39%);
    border-radius: 50%;
    text-transform: uppercase;
    color: #fff;
    margin-top: 60px;
}
.violetBtn p {
    text-align: center;
    padding-top: 30%;
    font-size: 15px;
}
.backHref{
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #dae0f2;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
}
.story-full-text, .story-full-text a{
    min-width: 300px;
    text-transform: uppercase;
    color: #dae0f2;
    font-size: 30px;
    padding: 60px 20px;
}
.story-full-text a {
    padding: 0 !important;
}
.story-p-text{

    min-width: 300px;
    text-transform: uppercase;
    color: #dae0f275;
    font-size: 18px;
    padding: 30px 20px;
}
.story-mobile-ellipse{
    content: '';
    width: 375px;
    height: 375px;
    background-image: url('../../images/foto.jpg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    background-size: cover;
    margin-left: -15%;
}
@media (max-width:375px) {
    .violetBtn{
        margin-left: calc(37%);
        margin-top: 150px;
    }
    .story-mobile .casesBtn{
        bottom: 463px;
    }
}
.posMob1{
    bottom: 390px;
    right: 30px;
}

@media (max-width: 425px) {
    .story-footer-left a {
        font-size: 18px !important;
        font-weight: 500 !important;
        margin-bottom: 36px !important;
    }
}