.video-object {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1000;
}
.fake-video-toner {
    position: absolute !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9 !important;
    left: 0 !important;
    top: 0 !important;
}
.video-toner {
    background: rgb(255, 255, 255, 0);
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.8432948179271709) 100%
    );
    position: absolute !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9 !important;
    left: 0 !important;
    top: 0 !important;
}
.visibleCursor * {
    cursor: default !important;
}
.video-controls {
    z-index: 10;
    color: #fff;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: flex-end;
    padding: 0 60px;
    bottom: 60px;
    width: 100%;
    background-color: transparent;
}
.video-controls-hide {
    align-items: center !important;
}
.video-controls-hide .video-center {
    width: 87% !important;
}
.video-controls-hide .bar {
    width: 100% !important;
}
.video-video video {
    height: 100vh;
    object-fit: cover;
}
.video-center {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
}
.video-text {
    font-size: 18px;
    width: 72px;
    text-transform: uppercase;
    color: #edf5fc !important;
    bottom: 0;
}
.play-mute {
    display: flex;
    align-items: center;
}
.play-btn {
    background-color: #eebf1b;
    width: 86px;
    height: 36px;
    text-transform: uppercase;
    color: #071013;
    border: none;
    font-size: 18px;
    line-height: 23.76px;
    letter-spacing: 1px;
    margin-right: 20px;
    animation: fadeInRev;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transition: 0.3s ease-out;
}
.fullscreen-rev {
    background-image: url('../Video/VideoSrc/fullscreen-back.png') !important;
}
.fullscreen,
.fullscreen-rev {
    width: 36px;
    height: 36px;
    background-image: url('../Video/VideoSrc/fullscreen.png');
    border: none;
    background-position: center;
    transition: all 0.3s ease !important;
    background-size: 80%;
    background-repeat: no-repeat;
    animation: fadeInRev;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transition: 0.3s ease-out;
    background-color: #eebf1b;
}
.muted-btn {
    margin-right: 20px;
    background-color: #7344f4;
    width: 86px;
    height: 36px;
    text-transform: uppercase;
    color: #edf5fc;
    border: none;
    font-size: 18px;
    line-height: 23.76px;
    letter-spacing: 1px;
    animation: fadeInRev;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transition: 0.3s ease-out;
}
.hide-controls {
    padding-bottom: 0px !important;
    text-transform: uppercase;
    color: #edf5fc;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    height: 36px;
    transition: all cubic-bezier(0.165, 0.84, 0.44, 1);
}
.fadeIn {
    transform: translateY(0);
    transition: 0.3s ease-out;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    -webkit-transform: translateY(0);
    -webkit-transition: 0.3s ease-out;
    -webkit-animation: fadeIn;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
}
@keyframes fadeIn {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    to {
        -webkit-display: none !important;
        -webkit-opacity: 0;
        -webkit-transform: translateY(100%);
        display: none !important;
        opacity: 0;
        transform: translateY(100%);
    }
}
@keyframes fadeInRev {
    from {
        -webkit-opacity: 0;
        opacity: 0;
    }
    to {
        -webkit-display: block;
        -webkit-opacity: 1;
        opacity: 1;
        display: block;
    }
}
.full-progressbar {
    position: relative;
    width: 100%;
    align-items: center;
    margin-bottom: 7px;
    transition: all 0.3s ease;
    -webkit-position: relative;
    -webkit-width: 100%;
    -webkit-align-items: center;
    -webkit-margin-bottom: 7px;
    -webkit-transition: all 0.3s ease;
}
.progress-bar-width {
    -webkit-width: 100% !important;
    -webkit-transition: all 0.3s ease;
    width: 100% !important;
    transition: all 0.3s ease;
}

/* input */
.inputProgress {
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    width: 95%;
    -webkit-appearance: none;
    height: 3px;
    border-radius: 0px;
    outline: none;
    background-color: rgba(238, 245, 252, 0.24);
    -webkit-transition: all 0.3s ease;
    -webkit-position: relative;
    -webkit-z-index: 3;
    -webkit-width: 95%;
    -webkit-height: 10px;
    -webkit-border-radius: 0px;
    -webkit-outline: none;
    -webkit-background-color: rgba(238, 245, 252, 0.24);
}
.bar {
    position: absolute;
    border-radius: 0px;
    z-index: 2;
    left: 0;
    top: 12px;
    height: 5px;
    width: 95%;
    background-color: transparent;
    overflow: hidden;

    -webkit-position: absolute;
    -webkit-border-radius: 0px;
    -webkit-z-index: 2;
    -webkit-left: 0px;
    -webkit-top: 6px;
    -webkit-width: 95%;
    -webkit-height: 10px;
    -webkit-background-color: transparent;
    -webkit-overflow: hidden;
}
.fill {
    position: absolute;
    z-index: 5;
    display: block;
    width: 0;
    height: 100%;
    background-color: #e4b50b;
    transition: all 1s linear !important;

    -webkit-position: absolute;
    -webkit-z-index: 5;
    -webkit-display: block;
    -webkit-width: 0;
    -webkit-height: 100%;
    -webkit-background-color: #e4b50b;
    -webkit-transition: all 1s linear !important;
}
input[type=range].inputProgress::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border: none !important;
}
input[type=range].inputProgress::-moz-slider-thumb {
    -moz-appearance: none;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border: none !important;
}
input[type=range].inputProgress::-moz-range-thumb {
    -moz-appearance: none;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border: none !important;
}
input[type=range].inputProgress::slider-thumb {
    appearance: none;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
    border: none !important;
}
.hide-controls-show {
    background-color: #071013 !important;
    padding: 6px 18px !important;
    height: auto !important;
}

.paginationSliders {
    position: absolute;
    top: 69px;
    left: 60px;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 100%;
    text-transform: uppercase; 
    color: #DAE0F2;
    z-index: 11;
}

.paginationSliders-current {
    color: #edf5fc !important;
}
