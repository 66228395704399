.hireLayout {
    overflow: visible !important;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: #000;
    transition: clip-path 0.3s ease;
    animation: hireShow;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(.67,.51,.51,.91);
    animation-fill-mode: forwards;
    clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
    -webkit-clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
}
.hireBLock div {
    overflow: visible !important;
}
.hireFullScreen {
    z-index: 2000;
    -webkit-clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
    clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
    transition: clip-path 0.3s;
    animation: toFullScreenHire 0.3s cubic-bezier(.67,.51,.51,.91)
        forwards;
    transition: clip-path 0.3s;
}
@keyframes toFullScreenHire {
    0% {
        -webkit-clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
        clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
    }
    100% {
        -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
    }
}
.hireLayoutReversed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    animation: hireShowBack;
    animation-timing-function: cubic-bezier(.67,.51,.51,.91);
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}
@keyframes hireShowBack {
    0% {
        /* opacity: 1; */
        -webkit-clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
        clip-path: polygon(0% 0%, 85% 0%, 94% 92%, 0% 100%);
    }
    35% {
        -webkit-clip-path: polygon(0 0, 15% 0, 94% 92%, 0 15%);
        clip-path: polygon(0 0, 15% 0, 94% 92%, 0 15%);
        /* opacity: 0.5; */
    }
    100% {
        /* opacity: 0; */
        display: none;
        -webkit-clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
        clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
    }
}
@keyframes hireShow {
    0% {
        /* opacity: 0; */
        -webkit-clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
        clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
    }
    35% {
        -webkit-clip-path: polygon(0 0, 16% 0, 94% 92%, 0 15%);
        clip-path: polygon(0 0, 16% 0, 94% 92%, 0 15%);
        /* opacity: 0.8; */
    }
    100% {
        opacity: 1 !important;
        -webkit-clip-path: polygon(0% 0%, 84.5% 0%, 97% 96%, 0% 100%);
        clip-path: polygon(0% 0%, 84.5% 0%, 97% 96%, 0% 100%);
    }
}

.hireBtnPage p {
    padding-top: 9% !important;
}


.gradientBtn {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    color: #fff;
    font-size: 14px;
    z-index: 1000001 !important;
    background: linear-gradient(125deg, #7344f4 10%, #eec01b 50%, #7344f4 90%);
    background-size: 600% 600%;
    box-shadow: 0 0 0 0 #eec01b;
    animation: gradient 5s ease infinite;
}
.shape {
    content: '';
    position: absolute;
    animation: morph 8s ease-in-out infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 124px;
    transition: all 1s ease-in-out;
    width: 124px;
    z-index: 1000000 !important;
    box-shadow: 0 0 0 1px #7344f485, 0 0 0 2px #7344f485, 0 0 0 5px #7344f485,
        0 0 0 12px #7344f485;
}
.pos1 {
    top: 70px;
    left: 70px;
}
@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

.gradientBtn p {
    transform: translateY(-50%);
    top: 50%;
    position: relative;
}
@keyframes gradient {
    0% {
        background-position: 0% 14%;
    }
    50% {
        background-position: 100% 87%;
    }
    100% {
        background-position: 0% 14%;
    }
}
.violet_on_hover:hover {
    color: #fff !important;
    transition: ease-in-out 0.3s all;
    background-color: #7344F4 !important;
    opacity: 1;
}
.violet_on_hover {
    transition: ease-in-out 0.3s all;
}
.hireBtn {
    top: 60px;
    left: 60px;
}
.hireBLock {
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 480px;
    margin-top: 240px;
    font-weight: 300;
    color: #fff !important;
}
.hireBLock__left {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    margin-right: 172px;
    justify-content: space-between;
}
.hireBLock__left__top a {
    color: #DAE0F2;
    text-transform: uppercase;
    font-weight: 300;
    margin-right: 48px;
}
.hireBLock__left__middle {
    color: #dae0f2;
    text-transform: uppercase;
    font-weight: 450;
    opacity: 0.6;
    margin-top: 96px;
}
.hireBLock__right__middle h3,
.hireBLock__right__middle h3 a,
.contact__tel,
.hireBLock a {
    font-weight: 450 !important;
    color: #DAE0F2;
    font-size: 21px !important;
}
.hireBLock__left__bottom {
    font-size: 35px;
    font-weight: 400;
    margin-top: 72px;
}
.hireBLock__right {
    z-index: 2000;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
}
.hireBLock__right div {
    
}
.hireBLock__right__top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #dae0f2;
    opacity: 0.6;
    font-weight: 300;
    text-transform: uppercase;
}
.hireBLock__right__top p {
    margin-right: 57px;
}
.hireBLock__right__top a {
    color: #dae0f2;
    font-weight: 300;
    font-weight: normal;
    text-decoration: underline;
}
.hireBLock__right__middle {
    margin-top: 96px;
}
.hireBLock__right__middle p {
    color: #dae0f2;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 24px;
}
.hireBLock__right__middle h3,.hireBLock__right__middle h3 a {
    font-size: 48px !important;
}
.hireBLock__right__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 108px;
    font-weight: normal;
    font-size: 18px;
}
.hireBLock__right__bottom .hbr_col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}
.hireBLock__right__bottom .contact {
    margin-right: 48px;
    min-width: 148px;
 }
.contact p:nth-child(1) {
    color: #dae0f2;
    text-transform: uppercase;
    font-weight: 450;
    opacity: 0.6;
}
.contact p:nth-child(2) {
    color: #eebf1b;
    opacity: 0.6;
    font-weight: 600;
    padding-bottom: 24px;
    text-transform: uppercase;
}
.contactss p:nth-child(1) {
    opacity: 1 !important;
}
.contactss p:nth-child(2) {
    opacity: 1 !important;
}
.contact__tel p, .contact__tel p a{
    color: #fff;
    opacity: 1 !important;
    text-transform: lowercase !important;
    font-size: 20px;
}
.contact__tel p a {
    font-size: 20px !important;
 }
.contact__tel a {
    font-size: 20px;
    margin-top: 12px;
    font-size: 24px !important;
    color: #dae0f2;
}
.span__special__color {
    color: #EEBF1B !important;
}

.special__hire {
    color: #EEBF1B !important;
    font-weight: 450 !important;
}

.special__hire_2 {
    color: #EDF5FC !important;
    font-weight: 300 !important;
}

@media (max-width: 1900px) {
    @keyframes hireShow {
        0% {
            /* opacity: 0; */
            -webkit-clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
            clip-path: polygon(0 0, 0 0, 94% 92%, 0 0);
        }
        35% {
            -webkit-clip-path: polygon(0 0, 16% 0, 94% 92%, 0 15%);
            clip-path: polygon(0 0, 16% 0, 94% 92%, 0 15%);
            /* opacity: 0.8; */
        }
        100% {
            /* opacity: 1; */
            -webkit-clip-path: polygon(0% 0%, 85% 0%, 95% 92%, 0% 100%);
            clip-path: polygon(0% 0%, 85% 0%, 95% 92%, 0% 100%);
        }
    }
}
