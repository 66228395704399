.MainPageButton {
    position: relative;
    text-transform: uppercase;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 450;
    color: #EDF5FC;
    transition: 0.3s ease-in-out;
    animation: showMainPageBtn 0.4s;
    
}
.scaleCursor1 {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 2000;
    transform: translate(0);
}
.firstCircle {
    position: absolute;
    top: 98px !important;
    left: 50%;
    margin-left: -50px;
    margin-top: -6px;
    z-index: 1000;
}
.firstCircle:hover {
    transition: all 0.3s ease;
}
.secondCircle:hover {
    transition: all 0.3s ease;
}
.thirdCircle:hover {
    transition: all 0.3s ease;
}
.secondCircle {
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 1000;
}
.thirdCircle {
    position: absolute;
    bottom: 40px;
    right: 60px;
    z-index: 1000;
}
@keyframes showMainPageBtn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
.clickedCases {
    background-color: #7344f4;
    width: 85px;
    height: 85px;
    background-image: url('../../images/CloseWhite.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000011;
    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: -50px;
    border: none !important;
    animation: pulsebackground 2s ease;
}
.clickedCases:hover::before {
    border: none !important;
}
.clickedStory:hover::before {
    border: none !important;
}
.clickedHire:hover::before {
    border: none !important;
}
.clickedCases:hover {
    border: none !important;
    background-color: #fff;
    animation: pulseclicked 2s ease;
    background-image: url('../../images/CloseBlack.svg');
}
.clickedStory {
    transition: all 0.3s ease-in-out;
    background-color: #7344f4;
    width: 85px;
    height: 85px;
    background-image: url('../../images/CloseWhite.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 100002;
    position: absolute;
    border: none !important;
    bottom: 40px;
    left: 60px;
    border: none;
    animation: pulsebackground 2s ease;
}
.clickedStory:hover {
    border: none;
    animation: pulseclicked 2s ease;
    background-color: #fff !important;
    background-image: url('../../images/CloseBlack.svg');
}
.clickedHire {
    transition: all 0.3s ease-in-out;
    background-color: #7344f4;
    width: 85px;
    height: 85px;
    background-image: url('../../images/CloseWhite.svg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000011;
    position: absolute;
    border: none !important;
    bottom: 40px;
    right: 60px;
    border: none;
    animation: pulsebackground 2s ease;
}
.clickedHire:hover {
    border: none;
    animation: pulseclicked 2s ease;
    background-color: #fff !important;
    background-image: url('../../images/CloseBlack.svg');
}
@keyframes pulsebackground {
    0% {
        box-shadow: 0 0 0 0 #7344f4a9;
    }
    70% {
        box-shadow: 0 0 0 10px #7344f400;
    }
    100% {
        box-shadow: 0 0 0 0 #7344f400;
    }
}
@keyframes pulseclicked {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.699);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.movedHoveredButton {
    transform: translateX(-43%) translateY(-43%) !important;
    position: fixed;
}

#cases_movedHoveredButton {
    -webkit-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    -moz-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    position: fixed;
    z-index: 8;
    transform: translateX(-50%);
    top: 10%;
    left: 50%;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 450;
    color: #EDF5FC;
}

#story_movedHoveredButton {
    -webkit-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    -moz-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    position: fixed;
    z-index: 8;
    transform: translateX(-50%) translateY(-50%);
    top: calc(100% - 90px);
    left: 90px;
    font-size: 18px;
    font-weight: 450;
    text-transform: uppercase;
    color: #EDF5FC;
}

#hireus_movedHoveredButton {
    -webkit-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    -moz-transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    transition: all 0.3s cubic-bezier(.23,.49,.37,.77);
    position: fixed;
    z-index: 8;
    transform: translateX(-50%) translateY(-50%);
    top: calc(100% - 90px);
    left: calc(100% - 110px);
    font-size: 18px;
    font-weight: 450;
    color: #EDF5FC;
    text-transform: uppercase;
}

.notTransition {
    
}

@media not all and (min-resolution:.001dpcm) { 

    .clickedCases, .clickedStory , .clickedHire {
        background-size: auto !important;
    }
}
